import { Link } from 'apollo-react';
import Close from 'apollo-react-icons/Close';
import Download from 'apollo-react-icons/Download';
import Upload from 'apollo-react-icons/Upload';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import CardContent from 'apollo-react/components/CardContent';
import CardHeader from 'apollo-react/components/CardHeader';
import Container from 'apollo-react/components/Container';
import DatePicker from 'apollo-react/components/DatePicker';
import Divider from 'apollo-react/components/Divider';
import FileUpload from 'apollo-react/components/FileUpload';
import Grid from 'apollo-react/components/Grid';
import InputLabel from 'apollo-react/components/InputLabel';
import Radio from 'apollo-react/components/Radio';
import TextField from 'apollo-react/components/TextField';
import TimePicker from 'apollo-react/components/TimePicker';
import Typography from 'apollo-react/components/Typography';
import { unionWith } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmDialog from '../common/ConfirmDialog';
import { API_URL, NO_SEND_FLG, OBJ_ROLE, PROMPTLY_FLG, STATUS_CODE, SYS_ROLE } from '../common/Constants';
import DetailDialog from '../common/DetailDialog';
import Dialog from '../common/Dialog';
import Loading from '../common/Loading';
import { checkFileName, getApi, getFuncroleFlg, getHeader, postApi, setMessage, setURL, toISOStringWithTimezone, redirectToLogin, MaintenanceApiError, CustomApiError } from '../common/Utils';
import downloadData from '../json/downloadData.json';
import uploadUpdateData from '../json/uploadUpdateData.json';
import './UploadUpdate.scss';

/**
 * 納品アップロード_変更
 */
function UploadUpdate() {
	const location = useLocation();
	const [t, i18n] = useTranslation();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const DIALOG_STATUS = {
		NO_DIALOG: '0',
		OK_DIALOG: '1',
		DETAIL_DIALOG: '2',
		UPLOAD_CONFIRM_DIALOG: '3',
		UPLOAD_COMPLETE_DIALOG: '4',
		OK_DIALOG_BACK: '5',
		DETAIL_DIALOG_BACK: '6',
	};
	const [dialogStatus, setDialogStatus] = useState(DIALOG_STATUS.NO_DIALOG);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogContent, setDialogContent] = useState('');
	const [detailContent, setDetailContent] = useState('');
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const [confirmDialogContent, setConfirmDialogContent] = useState('');

	// ファイル選択の権限フラグ
	const [uploadFileSelectorAuthFlg, setUploadFileSelectorAuthFlg] = useState(false);
	// ファイルダウンロードリンクの権限フラグ
	const [downloadFileLinkAuthFlg, setDownloadFileLinkAuthFlg] = useState(false);
	// 特記事項の権限フラグ
	const [noticeAuthFlg, setNoticeAuthFlg] = useState(false);
	// お知らせの権限フラグ
	const [informationAuthFlg, setInformationAuthFlg] = useState(false);
	// 公開日時の権限フラグ
	const [openDateChkAuthFlg, setOpenDateChkAuthFlg] = useState(false);
	// 通知メールの送信の権限フラグ
	const [mailSendChkAuthFlg, setMailSendChkAuthFlg] = useState(false);
	// アップロードボタンの権限フラグ
	const [uploadFileBtnAuthFlg, setUploadFileBtnAuthFlg] = useState(false);
	// ステータスによる制御
	const [statusFlg, setStatusFlg] = useState(false);

	// 画面に表示されている情報
	const [formValues, setFormValues] = useState({
		orgCode: '',
		orgName: '',
		serviceCode: '',
		serviceName: '',
		projCode: '',
		projName: '',
		projRateNo: '',
		fileId: '',
		fileName: '',
		selectedFiles: [],
		beforeNotices: '',
		notices: '',
		beforeInformation: '',
		information: '',
		beforePromptlyFlg: '',
		promptlyFlg: '',
		beforeOpenDisplayDate: null,
		openDate: null,
		openDisplayDate: null,
		beforeOpenTime: '',
		openTime: '',
		beforeNoSendFlg: '',
		noSendFlg: '',
		projStatus: '',
		update: '',
	});
	// エラーのファイル名のリスト
	const [errFileNameMessage, setErrFileNameMessage] = useState('');
	// 公開日時の日付形式不正フラグ
	const [openDateFormatErrFlg, setOpenDateFormatErrFlg] = useState(false);
	// 公開日時の過去日判定フラグ
	const [openDatePastErrFlg, setOpenDatePastErrFlg] = useState(false);
	// 公開日時の時刻形式不正フラグ
	const [openTimeFormatErrFlg, setOpenTimeFormatErrFlg] = useState(false);

	/**
	 * アップロード履歴(特定案件)取得APIの成功時の処理
	 * @param {object} response アップロード履歴(特定案件)取得APIのレスポンス
	 */
	function initApiSuccess(response) {
		response.beforeNotices = response.notices;
		response.beforeInformation = response.information;
		response.beforePromptlyFlg = response.promptlyFlg;
		if (response.promptlyFlg === PROMPTLY_FLG.PUBLISH_SELECT_DATE) {
			response.beforeOpenDisplayDate = moment(response.openDate, 'yyyy/MM/DD');
			response.openDisplayDate = moment(response.openDate, 'yyyy/MM/DD');
			response.beforeOpenTime = moment(response.openDate).format('hh:mm A');
			response.openTime = moment(response.openDate).format('hh:mm A');
			response.openDate = response.openDisplayDate;
			const option = {year: 'numeric', month: '2-digit', day: '2-digit'};
			const today = new Date().toLocaleDateString('ja-JP', option);
			if (response.openDate._d.toLocaleDateString('ja-JP', option) < today) {
				setOpenDatePastErrFlg(true);
			}
		}
		response.beforeNoSendFlg = response.noSendFlg;
		const merged = {...location.state, ...response};
		setFormValues({ ...formValues, ...merged });
		if (response.projStatus === STATUS_CODE.DELIVERY_UPLOAD_COMPLETE || response.projStatus === STATUS_CODE.DELIVERY_STORAGE_COMPLETE) {
			setStatusFlg(true);
		}
	};

	/**
	 * ダウンロードAPIの成功時の処理
	 * @param {object} response ダウンロードAPIのレスポンス
	 */
	async function downloadApiSuccess(response) {
		try {
			const fileName = response.fileDownloadName;
			const options = { 'suggestedName': fileName };
			const handle = await window.showSaveFilePicker(options);
			const writable = await handle.createWritable();
			const blob = new Blob([Uint8Array.from(atob(response.fileContents), str => str.charCodeAt(0))]);
			await writable.write(blob);
			await writable.close();
		} catch (error) {
		}
	};

	/**
	 * アップロードAPIの成功時の処理
	 */
	function uploadSuccess() {
		setDialogStatus(DIALOG_STATUS.UPLOAD_COMPLETE_DIALOG);
		var message = "";
		if (formValues.noSendFlg == NO_SEND_FLG.SEND) {
			message = t('message.1000600');
		} else {
			message = t('message.1000610');
		}
		setDialogOpen(true);
		setDialogContent(message);
	};

	/**
	 * アップロード履歴(特定案件)取得APIの失敗時の処理
	 * @param {object} error エラーオブジェクト
	 */
	function initApiError(error) {
		const detailErrorMessage = '初期処理でエラーが発生しました。\n(An error occurred during the initial processing.)';
		commonError(error, detailErrorMessage);

		//認証期限切れの場合は再ログイン
		redirectToLogin();
	};

	/**
	 * ダウンロードAPIの失敗時の処理
	 * @param {object} error エラーオブジェクト
	 */
	function downloadApiError(error) {
		const detailErrorMessage = 'ダウンロード処理でエラーが発生しました。\n(An error occurred during the download process.)';
		commonError(error, detailErrorMessage);

		//認証期限切れの場合は再ログイン
		redirectToLogin();
	};

	/**
	 * アップロードAPIの失敗時の処理
	 * @param {object} error エラーオブジェクト
	 */
	function uploadError(error) {
		if (error.name === 'MaintenanceApiError') {
			navigate('/', { state: { maintenanceFlg: true, }});
		} else if (error.name === 'CustomApiError') {
			//入力テキストに禁則文字が含まれている場合、azureのインフラルールによりエラーとなる
			//ただし、エラーは403 Forbiddenとしか返って来ない（＝禁則文字によるエラーだと判別できない）ため、メッセージを変更する
			const forbidCharPat1 = /.+403 Forbidden.+/;
			const forbidCharPat2 = /.+Microsoft-Azure-Application-Gateway.+/;
			if ((error.message.match(forbidCharPat1)) && (error.message.match(forbidCharPat2))) {
				const message = t('message.1000810');
				setDialogStatus(DIALOG_STATUS.OK_DIALOG);
				setDialogOpen(true);
				setDialogContent(message);
			} else {
				const message = error.message;
				setDialogStatus(DIALOG_STATUS.OK_DIALOG);
				setDialogOpen(true);
				setDialogContent(message);
			}
		} else {
			const message = 'アップロード処理でエラーが発生しました。\n(An error occurred during the upload process.)';
			setDialogStatus(DIALOG_STATUS.DETAIL_DIALOG);
			setDialogOpen(true);
			setDialogContent(message);
			setDetailContent(error.stack);
		}

		//認証期限切れの場合は再ログイン
		redirectToLogin();
	};

	/**
	 * 共通エラー処理
	 * @param {object} error エラーオブジェクト
	 * @param {string} detailErrorMessage 詳細ダイアログに設定するエラーメッセージ
	 */
	const commonError = (error, detailErrorMessage) => {
		if (error.name === 'MaintenanceApiError') {
			navigate('/', { state: { maintenanceFlg: true, }});
		} else if (error.name === 'CustomApiError') {
			const message = error.message;
			setDialogStatus(DIALOG_STATUS.OK_DIALOG);
			setDialogOpen(true);
			setDialogContent(message);
		} else {
			setDialogStatus(DIALOG_STATUS.DETAIL_DIALOG);
			setDialogOpen(true);
			setDialogContent(detailErrorMessage);
			setDetailContent(error.stack);
		}
	};

	/**
	 * ファイルのダウンロード時の処理
	 */
	const handleDownloadClick = async () => {
		try {
			//ファイル保存ダイアログを開きダウンロード先指定
			const fileName = formValues.fileName;
			const options = { 'suggestedName': fileName };
			const handle = await window.showSaveFilePicker(options);
			// ファイル保存ダイアログで入力したファイル名を取得
			const enteredFileName = await handle.name;
			console.log(`[IQVIA FRONT] [WebApp] [INFO] entered file name: ${enteredFileName}`);
			// ファイル保存ダイアログで指定したディレクトリ情報（親フォルダのハンドルがある場合のみ）を取得
			const parentDirectory = handle.kind === "file" && handle.isDirectory ? handle.directory : null;
			console.log(`[IQVIA FRONT] [WebApp] [INFO] parent directory: ${parentDirectory}`);
			// 書き込み用のストリームを開く
			const writableStream = await handle.createWritable();
			// ダウンロードAPI
			const url = setURL(API_URL.DOWNLOAD) + '/' + formValues.fileId + '?';
			// クエリパラメータ作成
			const params = {};
			params['auditCode'] = formValues.serviceCode;
			params['projCode'] = formValues.projCode;
			params['projRateNo'] = formValues.projRateNo;
			const urlSearchParam =  String(new URLSearchParams(params));
			//ダウンロードAPI実行中は操作できないようにする
			document.getElementById('block').style.display = 'block';
			const response = await fetch(url + urlSearchParam, getHeader(SYS_ROLE.UPLOAD_UPDATE));
			if (!response.ok) {
				if (response.status === 503) {
					localStorage.removeItem("funcroleList");
					throw new MaintenanceApiError();
				}
				throw new CustomApiError(await response.text());
			}

			// サーバーからファイルをダウンロードしBlobとして取得
			const blob = await response.blob();
			// Blobデータを書き込む
			await writableStream.write(blob);
			// 書き込み完了後にストリームを閉じる
			await writableStream.close();
		} catch (error) {
			const detailErrorMessage = 'ダウンロード処理で処理でエラーが発生しました。\n(An error occurred during the select item process.)';
			console.log(`[IQVIA FRONT] [WebApp] [ERROR] ダウンロード処理で処理でエラーが発生: ${error.message}`);
			commonError(error, detailErrorMessage);
		} finally {
			document.getElementById('block').style.display = 'none';
		}
	}

	/**
	 * ファイル選択時の処理
	 * @param {object} files 選択したファイルの情報
	 */
	const handleUploadFile = (files) => {
		const selectedFiles = unionWith(
			formValues.selectedFiles,
			files,
			(item1, item2) => {
				return item1.name === item2.name;
			},
		);
		const errFileNameList = selectedFiles.filter(selectedFile => checkFileName(selectedFile.name) === false).map(errFile => errFile.name);
		if (errFileNameList.length > 0) {
			setErrFileNameMessage(errFileNameList.join(', '));
		} else {
			setErrFileNameMessage('');
		}
		setFormValues({ ...formValues, selectedFiles });
	}

	/**
	 * ファイル削除時の処理
	 * @param {object} file 削除したファイルの情報
	 */
	const handleDeleteFile = (file) => {
		const selectedFiles = formValues.selectedFiles.filter((item) => item.name !== file.name);
		const errFileNameList = selectedFiles.filter(selectedFile => checkFileName(selectedFile.name) === false).map(errFile => errFile.name);
		if (errFileNameList.length > 0) {
			setErrFileNameMessage(errFileNameList.join(', '));
		} else {
			setErrFileNameMessage('');
		}
		setFormValues({ ...formValues, selectedFiles });
	}

	/**
	 * 公開日時で即時公開を選択時の処理
	 */
	const selectPublishImmediately = () => {
		setFormValues({ ...formValues, promptlyFlg: PROMPTLY_FLG.PUBLISH_IMMEDIATELY, openDate: null, openDisplayDate: moment(null), openTime: ''});
		clearOpenDateErrFlg();
		setOpenTimeFormatErrFlg(false);
	};

	/**
	 * 公開日時の日付入力時の処理
	 * @param {string} date 入力した日付
	 */
	const inputDate = (date) => {
		const option = {year: 'numeric', month: '2-digit', day: '2-digit'};
		const today = new Date().toLocaleDateString('ja-JP', option);
		if (date === '') {
			setFormValues({ ...formValues, openDate: null, openDisplayDate: null});
			clearOpenDateErrFlg();
		} else if (!(moment(date, 'yyyy/MM/DD', true).isValid())) {
			setFormValues({ ...formValues, openDate: moment(date, 'yyyy/MM/DD'), openDisplayDate: null});
			setOpenDateFormatErrFlg(true);
			setOpenDatePastErrFlg(false);
		} else if (today <= moment(date, 'yyyy/MM/DD', true)._d.toLocaleDateString('ja-JP', option)) {
			setFormValues({ ...formValues, openDate: moment(date, 'yyyy/MM/DD'), openDisplayDate: moment(date, 'yyyy/MM/DD')});
			clearOpenDateErrFlg();
		} else {
			setFormValues({ ...formValues, openDate: moment(date, 'yyyy/MM/DD'), openDisplayDate: moment(date, 'yyyy/MM/DD')});
			setOpenDateFormatErrFlg(false);
			setOpenDatePastErrFlg(true);
		}
	};

	/**
	 * 公開日のエラーフラグをfalseに設定する処理
	 */
	const clearOpenDateErrFlg = () => {
		setOpenDateFormatErrFlg(false);
		setOpenDatePastErrFlg(false);
	};

	/**
	 * 公開日時の時刻入力時の処理
	 * @param {string} time 入力した時刻
	 */
	const inputTime = (time) => {
		setFormValues({ ...formValues, openTime: time});
		if (time === '' || moment(time, 'hh:mm A', true).isValid()) {
			setOpenTimeFormatErrFlg(false);
		} else {
			setOpenTimeFormatErrFlg(true);
		};
	};

	/**
	 * アップロードボタン押下時の処理
	 */
	const handleUploadClick = () => {
		var message = "";
		if (formValues.promptyFlg == "0") {
			var openDate = toISOStringWithTimezone(moment(formValues.openDate.format('yyyy-MM-DD') + ' ' + formValues.openTime)._d);
			var now = toISOStringWithTimezone(new Date());
			//指定した公開日時が現在時刻以前の場合はメッセージ表示
			if (openDate <= now) {
				message = t('message.1001300');
				setDialogStatus(DIALOG_STATUS.OK_DIALOG);
				setDialogOpen(true);
				setDialogContent(message);
			} else  {
				message = t('message.1000500');
				setDialogStatus(DIALOG_STATUS.UPLOAD_CONFIRM_DIALOG);
				setConfirmDialogOpen(true);
				setConfirmDialogContent(message)
			}
		} else {
			message = t('message.1000500');
			setDialogStatus(DIALOG_STATUS.UPLOAD_CONFIRM_DIALOG);
			setConfirmDialogOpen(true);
			setConfirmDialogContent(message)
		}
	}

	/**
	 * ダイアログでOKボタン押下時の処理
	 */
	const onOk = () => {
		setDialogStatus(DIALOG_STATUS.NO_DIALOG);
		setDialogOpen(false);
		if (dialogStatus === DIALOG_STATUS.OK_DIALOG_BACK || dialogStatus === DIALOG_STATUS.DETAIL_DIALOG_BACK) {
			// 納品ファイル管理画面へ遷移
			const param = {
				org: location.state.orgCode,
				service: location.state.serviceCode,
				proj: location.state.projCode,
			};
			navigate('/deliveryfilemanagement', { state: param });
		}

	};

	/**
	 * アップロード時の確認ダイアログでOKボタン押下時の処理
	 */
	const onUploadConfirmOk = async () => {
		setConfirmDialogOpen(false);
		let formData = new FormData();
		formData.append('auditCode', formValues.serviceCode);
		formData.append('projCode', formValues.projCode);
		formData.append('notices', formValues.notices);
		formData.append('information', formValues.information);
		formData.append('promptlyFlg', formValues.promptlyFlg);
		formData.append('update', formValues.update);
		if (formValues.promptlyFlg === PROMPTLY_FLG.PUBLISH_SELECT_DATE) {
			formData.append('openDate', toISOStringWithTimezone(moment(formValues.openDate.format('yyyy-MM-DD') + ' ' + formValues.openTime)._d));
		};
		formData.append('noSendFlg', formValues.noSendFlg);
		let url;
		if (formValues.selectedFiles.length === 0) {
			formData.append('projRateNo', formValues.projRateNo);
			// 案件更新API
			url = setURL(API_URL.PROJECT);
		} else {
			formData.append('orgLevel0Code', formValues.orgCode);
			for (let selectedFile of formValues.selectedFiles){
				formData.append('file', selectedFile);
			}
			// 納品アップロードリクエストAPI
			url = setURL(API_URL.UPLOAD_IQVIA_REPLACE);
		}

		const postApiParam = {
			url: url,
			sysRole: SYS_ROLE.UPLOAD_UPDATE,
			body: formData,
			success: uploadSuccess,
			error: uploadError,
		};
		postApi(postApiParam);
	};

	/**
	 * 確認ダイアログでキャンセルボタン押下時の処理
	 */
	const onCancel = () => {
		setDialogStatus(DIALOG_STATUS.NO_DIALOG);
		setConfirmDialogOpen(false);
	};

	/**
	 * 共通納品ファイル管理遷移処理
	 */
	const commonBack = () => {
		const param = {
			org: formValues.orgCode,
			service: formValues.serviceCode,
			proj: formValues.projCode,
		};
		// 納品ファイル管理画面へ遷移
		navigate('/deliveryfilemanagement', { state: param });
	};

	/**
	 * アップロード完了ダイアログでOKボタン押下時の処理
	 */
	const onCompleteOk = () => {
		setDialogStatus(DIALOG_STATUS.NO_DIALOG);
		setDialogOpen(false);
		commonBack();
	};

	/**
	 * キャンセルボタン押下時の処理
	 */
	const handleCancelClick = () => {
		commonBack();
	}

	useEffect(() => {
		(async () => {
			if (location.state === null) {
				// 納品ファイル管理画面へ遷移
				navigate('/deliveryFilemanagement');
				return;
			}
			// ファイル選択の権限フラグを設定
			setUploadFileSelectorAuthFlg(getFuncroleFlg(SYS_ROLE.UPLOAD_UPDATE, OBJ_ROLE.UPLOAD_FILE_SELECTOR));
			// ファイルダウンロードリンクの権限フラグを設定
			setDownloadFileLinkAuthFlg(getFuncroleFlg(SYS_ROLE.UPLOAD_UPDATE, OBJ_ROLE.DOWNLOAD_FILE_LINK));
			// 特記事項の権限フラグを設定
			setNoticeAuthFlg(getFuncroleFlg(SYS_ROLE.UPLOAD_UPDATE, OBJ_ROLE.NOTICES));
			// お知らせの権限フラグを設定
			setInformationAuthFlg(getFuncroleFlg(SYS_ROLE.UPLOAD_UPDATE, OBJ_ROLE.INFORMATION));
			// 公開日時の権限フラグを設定
			setOpenDateChkAuthFlg(getFuncroleFlg(SYS_ROLE.UPLOAD_UPDATE, OBJ_ROLE.OPEN_DATE_CHK));
			// 通知メールの送信の権限フラグを設定
			setMailSendChkAuthFlg(getFuncroleFlg(SYS_ROLE.UPLOAD_UPDATE, OBJ_ROLE.MAIL_SEND_CHK));
			// アップロードボタンの権限フラグを設定
			setUploadFileBtnAuthFlg(getFuncroleFlg(SYS_ROLE.UPLOAD_UPDATE, OBJ_ROLE.UPLOAD_FILE_BTN));

			// アップロード履歴(特定案件)取得API
			const url = setURL(API_URL.UPHIST_IQVIA);
			const id = location.state.serviceCode + '_' + location.state.projCode + '_' + location.state.projRateNo
			const getApiParam = {
				url: url + '/' + id,
				sysRole: SYS_ROLE.UPLOAD_UPDATE,
				success: initApiSuccess,
				error: initApiError,
				json: structuredClone(uploadUpdateData),
			}
			await getApi(getApiParam);
			setIsLoading(false);
		})();
	}, []);

	if (isLoading) {
		// ロード中の場合はローディング画面
		return (
			<Loading />
		);
	} else {
		return (
			<Container className="uploadUpdate" maxWidth="md">

				<Card className="card">
					<CardHeader
						title={t('uploadDelivery')}
						subheader={t('uploadDeliveryScreenDescription')}
					/>
					<CardContent>
						<Grid container>
							<Grid item md={12} className="griditem">
								<TextField
									label={'1. ' + t('selectOrg')}
									value={formValues.orgName}
									disabled
								/>
							</Grid>
							<Grid item md={12} className="griditem">
								<TextField
									label={'2. ' + t('selectService')}
									value={formValues.serviceName}
									disabled
								/>
							</Grid>
							<Grid item md={12} className="griditem">
								<TextField
									label={'3. ' + t('selectProj')}
									value={formValues.projName}
									disabled
								/>
							</Grid>
							<Grid item md={12} className="griditem uploadFileSelector">
								<InputLabel>{'4. ' + t('uploadFileSelect')}</InputLabel>
								<InputLabel >{t('beforeUploadFile')}</InputLabel>
								<Link className="downloadFileLink" style={{display: 'inline-flex', alignItems: 'center'}} disabled={!downloadFileLinkAuthFlg || !statusFlg} onClick={handleDownloadClick}>
									<Download fontSize="small" />{formValues.fileName}
								</Link>
								<div className='afterUploadFile'>
								<FileUpload
									label={t('afterUploadFile')}
									value={formValues.selectedFiles}
									onUpload={handleUploadFile}
									onFileDelete={handleDeleteFile}
									disabled={!uploadFileSelectorAuthFlg || !statusFlg}
									dropAreaLabels={{
										browseLinkText: t('dropAreaBrowseLinkText'),
										hoverText: t('dropAreaHoverText'),
									}}
									fullWidth
								/>
								</div>
								{errFileNameMessage.length !== 0 && (
									<Typography className='errorMessage' variant='caption'>
										{setMessage(t('message.1000800'), [errFileNameMessage])}
									</Typography>
								)}
							</Grid>
						</Grid>
						<Grid item md={12} className="griditem notices">
							<Typography variant="caption">
								{'5. ' + t('notice')}
							</Typography>
							<TextField
								label={t('beforeNotice')}
								value={formValues.beforeNotices}
								multiline
								minRows="2"
								fullWidth
								disabled
							/>
							<TextField
								label={t('afterNotice')}
								helperText={formValues.notices.length > 300 ? setMessage(t('message.1000300'), [t('notice').toLowerCase(), 300]) : t('helperTextNotice')}
								value={formValues.notices}
								onChange={(e) => setFormValues({ ...formValues, notices: e.target.value })}
								error={formValues.notices.length > 300}
								multiline
								minRows="2"
								optional
								fullWidth
								disabled={!noticeAuthFlg || !statusFlg}
							/>
						</Grid>
						<Grid item md={12} className="griditem information">
							<Typography variant="caption">
								{'6. ' + t('information')}
							</Typography>
							<TextField
								label={t('beforeInformation')}
								value={formValues.beforeInformation}
								multiline
								minRows="2"
								fullWidth
								disabled
							/>
							<TextField
								label={t('afterInformation')}
								helperText={formValues.information.length > 300 ? setMessage(t('message.1000300'), [t('information').toLowerCase(), 300]) : t('helperTextInformation')}
								value={formValues.information}
								onChange={(e) => setFormValues({ ...formValues, information: e.target.value })}
								error={formValues.information.length > 300}
								multiline
								minRows="2"
								optional
								fullWidth
								disabled={!informationAuthFlg || !statusFlg}
							/>
						</Grid>
						<Grid item md={12} className="griditem openDateChk">
							<InputLabel required >{'7. ' + t('publishedDate')}</InputLabel>
							<InputLabel className='beforePublishedDateLabel'>{t('beforePublishedDate')}</InputLabel>
							<Radio
								label={t('publishImmediately')}
								checked={formValues.beforePromptlyFlg === PROMPTLY_FLG.PUBLISH_IMMEDIATELY}
								disabled
							/>
							<br />
							<Radio
								label={t('publishSelectedDatetime')}
								checked={formValues.beforePromptlyFlg === PROMPTLY_FLG.PUBLISH_SELECT_DATE}
								disabled
							/>
							<div>
								<DatePicker
									value={formValues.beforeOpenDisplayDate}
									placeholder="yyyy/mm/dd"
									dateFormat='yyyy/MM/DD'
									disabled
								/>
								<TimePicker
									value={formValues.beforeOpenTime}
									disabled
								/>
							</div>
							<InputLabel >{t('afterPublishedDate')}</InputLabel>
							<Radio
								label={t('publishImmediately')}
								checked={formValues.promptlyFlg === PROMPTLY_FLG.PUBLISH_IMMEDIATELY}
								onChange={selectPublishImmediately}
								disabled={!openDateChkAuthFlg || !statusFlg}
							/>
							<br />
							<Radio
								label={t('publishSelectedDatetime')}
								checked={formValues.promptlyFlg === PROMPTLY_FLG.PUBLISH_SELECT_DATE}
								onChange={() => setFormValues({ ...formValues, promptlyFlg: PROMPTLY_FLG.PUBLISH_SELECT_DATE })}
								disabled={!openDateChkAuthFlg || !statusFlg}
							/>
							<div>
								<DatePicker
									className='openDate'
									value={formValues.openDisplayDate}
									helperText={openDateFormatErrFlg ? t('message.1000400') : openDatePastErrFlg ? t('message.1001300') : ''}
									onInputChange={inputDate}
									minDate={new Date()}
									error={openDateFormatErrFlg || openDatePastErrFlg}
									placeholder="yyyy/mm/dd"
									dateFormat='yyyy/MM/DD'
									disabled={!openDateChkAuthFlg || !statusFlg || formValues.promptlyFlg === PROMPTLY_FLG.PUBLISH_IMMEDIATELY}
								/>
								<TimePicker
									value={formValues.openTime}
									helperText={openTimeFormatErrFlg ? t('message.1001600') : ''}
									onChange={inputTime}
									error={openTimeFormatErrFlg}
									disabled={!openDateChkAuthFlg || !statusFlg || formValues.promptlyFlg === PROMPTLY_FLG.PUBLISH_IMMEDIATELY}
								/>
							</div>
							{formValues.promptlyFlg === PROMPTLY_FLG.PUBLISH_SELECT_DATE && (!formValues.openDate || formValues.openTime === '') && (
								<Typography className='errorMessage' variant='caption'>
									{t('message.1001400')}
								</Typography>
							)}
						</Grid>
						<Grid item md={12} className="griditem mailSendChk">
							<InputLabel required >{'8. ' + t('sendMail')}</InputLabel>
							<InputLabel className='beforeSendMailLabel'>{t('beforeSendMail')}</InputLabel>
							<Radio
								label={t('sendPublishDate')}
								checked={formValues.beforeNoSendFlg === '0'}
								disabled
							/>
							<br />
							<Radio
								label={t('noSend')}
								checked={formValues.beforeNoSendFlg === '1'}
								disabled
							/>
							<InputLabel >{t('afterSendMail')}</InputLabel>
							<Radio
								label={t('sendPublishDate')}
								checked={formValues.noSendFlg === NO_SEND_FLG.SEND}
								onChange={() => setFormValues({ ...formValues, noSendFlg: NO_SEND_FLG.SEND })}
								disabled={!mailSendChkAuthFlg || !statusFlg}
							/>
							<br />
							<Radio
								label={t('noSend')}
								checked={formValues.noSendFlg === NO_SEND_FLG.NO_SEND}
								onChange={() => setFormValues({ ...formValues, noSendFlg: NO_SEND_FLG.NO_SEND })}
								disabled={!mailSendChkAuthFlg || !statusFlg}
							/>
						</Grid>
						<Divider className="divider" type="dark" />
						<Button
							className="uploadFileBtn"
							variant="primary"
							icon={Upload}
							disabled={errFileNameMessage.length !== 0 || formValues.notices.length > 300 || formValues.information.length > 300 || openDateFormatErrFlg || openDatePastErrFlg || openTimeFormatErrFlg || (formValues.promptlyFlg === PROMPTLY_FLG.PUBLISH_SELECT_DATE && (!formValues.openDate || formValues.openTime === ''))}
							onClick={handleUploadClick}
							style={{ display: uploadFileBtnAuthFlg && statusFlg ? "inline-flex" : "none"}}
						>
							{t('upload')}
						</Button>
						<Button
							className="cancelBtn"
							variant="secondary"
							icon={Close}
							onClick={handleCancelClick}
						>
							{t('cancel')}
						</Button>
					</CardContent>
				</Card>
				{(dialogStatus === DIALOG_STATUS.OK_DIALOG || dialogStatus === DIALOG_STATUS.OK_DIALOG_BACK) && (
					<Dialog
						isOpen={dialogOpen}
						content={dialogContent}
						onOk={onOk}
					/>
				)}
				{(dialogStatus === DIALOG_STATUS.DETAIL_DIALOG || dialogStatus === DIALOG_STATUS.DETAIL_DIALOG_BACK) && (
					<DetailDialog
						isOpen={dialogOpen}
						content={dialogContent}
						detailContent={detailContent}
						onOk={onOk}
					/>
				)}
				{dialogStatus === DIALOG_STATUS.UPLOAD_CONFIRM_DIALOG && (
					<ConfirmDialog
						isOpen={confirmDialogOpen}
						content={confirmDialogContent}
						onOk={onUploadConfirmOk}
						onCancel={onCancel}
					/>
				)}
				{dialogStatus === DIALOG_STATUS.UPLOAD_COMPLETE_DIALOG && (
					<Dialog
						isOpen={dialogOpen}
						content={dialogContent}
						onOk={onCompleteOk}
					/>
				)}
			</Container>
		);
	}
}
export default UploadUpdate;
