import { React } from 'react';

import ReactLoading from 'react-loading';

function Loading() {

	return (
		<section className="flex justify-center items-center h-screen">
		<div>
			<div style={{width: "5%", margin: "20% auto 0 auto"}}>
				<ReactLoading type="spin" color="black" height="50px" width="50px" className="mx-auto" />
			</div>
			  <p className="text-center mt-3" style={{ textAlign:"center" }}>処理中です。しばらくお待ちください。</p>
		</div>
	  </section>
	);
}
export default Loading;