import Dialog from "apollo-react/components/Dialog";
import DialogActions from "apollo-react/components/DialogActions";
import DialogTitle from "apollo-react/components/DialogTitle";
import DialogContent from "apollo-react/components/DialogContent";
import DialogContentText from "apollo-react/components/DialogContentText";
import Button from 'apollo-react/components/Button';

export default function ConfirmDialog({
  isOpen,
  title,
  content,
  onOk,
  onCancel
}) {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ whiteSpace: 'pre-line' }}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onOk}>
          OK
        </Button>
        <Button onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}