import Button from 'apollo-react/components/Button';
import Dialog from "apollo-react/components/Dialog";
import DialogActions from "apollo-react/components/DialogActions";
import DialogContent from "apollo-react/components/DialogContent";
import DialogContentText from "apollo-react/components/DialogContentText";
import DialogTitle from "apollo-react/components/DialogTitle";
import { useState } from 'react';

export default function DetailDialog({
  isOpen,
  content,
  detailContent,
  onOk,
}) {
  const [detailContentFlg, setDetailContentFlg] = useState(false);
  const [detailButtonFlg, setDetailButtonFlg] = useState(true);

  /**
   * ダイアログで詳細ボタン押下時の処理
   */
  const onDetailClick = () => {
    setDetailContentFlg(true);
    setDetailButtonFlg(false);
  };

  return (
    <Dialog open={isOpen}>
      {!detailContentFlg && (
        <span>
          <DialogContent>
            <DialogContentText style={{ whiteSpace: 'pre-line' }}>
              {content}
            </DialogContentText>
          </DialogContent>
        </span>
      )}
      {detailContentFlg && (
        <span>
          <DialogTitle>
            詳細(Detail)
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ whiteSpace: 'pre-line' }}>
              {detailContent}<br/><br/>問い合わせ先(Contact address for inquiries)：IQVIAFrontOperation@iqvia.com
            </DialogContentText>
          </DialogContent>
        </span>
      )}
      <DialogActions>
        <Button onClick={onOk}>
          OK
        </Button>
        {detailButtonFlg && (
          <Button onClick={onDetailClick}>
            Detail
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}