import Close from 'apollo-react-icons/Close';
import Upload from 'apollo-react-icons/Upload';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import CardContent from 'apollo-react/components/CardContent';
import CardHeader from 'apollo-react/components/CardHeader';
import Container from 'apollo-react/components/Container';
import DatePicker from 'apollo-react/components/DatePicker';
import Divider from 'apollo-react/components/Divider';
import FileUpload from 'apollo-react/components/FileUpload';
import Grid from 'apollo-react/components/Grid';
import InputLabel from 'apollo-react/components/InputLabel';
import Radio from 'apollo-react/components/Radio';
import TextField from 'apollo-react/components/TextField';
import TimePicker from 'apollo-react/components/TimePicker';
import Typography from 'apollo-react/components/Typography';
import { unionWith } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmDialog from '../common/ConfirmDialog';
import { API_URL, NO_SEND_FLG, OBJ_ROLE, PROMPTLY_FLG, SYS_ROLE } from '../common/Constants';
import DetailDialog from '../common/DetailDialog';
import Dialog from '../common/Dialog';
import Loading from '../common/Loading';
import { checkFileName, getFuncroleFlg, postApi, setMessage, setURL, toISOStringWithTimezone, redirectToLogin } from '../common/Utils';
import './UploadDelivery.scss';

/**
 * 納品アップロード
 */
function UploadDelivery() {
	const location = useLocation();
	const [t, i18n] = useTranslation();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const DIALOG_STATUS = {
		NO_DIALOG: '0',
		OK_DIALOG: '1',
		DETAIL_DIALOG: '2',
		UPLOAD_CONFIRM_DIALOG: '3',
		UPLOAD_COMPLETE_DIALOG: '4',
	};
	const [dialogStatus, setDialogStatus] = useState(DIALOG_STATUS.NO_DIALOG);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogContent, setDialogContent] = useState('');
	const [detailContent, setDetailContent] = useState('');
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const [confirmDialogContent, setConfirmDialogContent] = useState('');

	// ファイル選択の権限フラグ
	const [uploadFileSelectorAuthFlg, setUploadFileSelectorAuthFlg] = useState(false);
	// 特記事項の権限フラグ
	const [noticeAuthFlg, setNoticeAuthFlg] = useState(false);
	// お知らせの権限フラグ
	const [informationAuthFlg, setInformationAuthFlg] = useState(false);
	// 公開日時の権限フラグ
	const [openDateChkAuthFlg, setOpenDateChkAuthFlg] = useState(false);
	// 通知メールの送信の権限フラグ
	const [mailSendChkAuthFlg, setMailSendChkAuthFlg] = useState(false);
	// アップロードボタンの権限フラグ
	const [uploadFileBtnAuthFlg, setUploadFileBtnAuthFlg] = useState(false);
	// 画面に表示されている情報
	const [formValues, setFormValues] = useState({
		orgCode: '',
		orgName: '',
		serviceCode: '',
		serviceName: '',
		projCode: '',
		projName: '',
		selectedFiles: [],
		notices: '',
		information: '',
		promptyFlg: PROMPTLY_FLG.PUBLISH_IMMEDIATELY,
		openDate: null,
		openDisplayDate: null,
		openTime: '',
		noSendFlg: NO_SEND_FLG.SEND,
	});
	// エラーのファイル名のリスト
	const [errFileNameMessage, setErrFileNameMessage] = useState('');
	// 公開日時の日付形式不正フラグ
	const [openDateFormatErrFlg, setOpenDateFormatErrFlg] = useState(false);
	// 公開日時の過去日判定フラグ
	const [openDatePastErrFlg, setOpenDatePastErrFlg] = useState(false);
	// 公開日時の時刻形式不正フラグ
	const [openTimeFormatErrFlg, setOpenTimeFormatErrFlg] = useState(false);

	/**
	 * アップロードAPIの成功時の処理
	 */
	function uploadSuccess() {
		setDialogStatus(DIALOG_STATUS.UPLOAD_COMPLETE_DIALOG);
		var message = "";
		if (formValues.noSendFlg == NO_SEND_FLG.SEND) {
			message = t('message.1000600');
		} else {
			message = t('message.1000610');
		}
		setDialogOpen(true);
		setDialogContent(message);
	};

	/**
	 * アップロードAPIの失敗時の処理
	 * @param {object} error エラーオブジェクト
	 */
	function uploadError(error) {
		if (error.name === 'MaintenanceApiError') {
			navigate('/', { state: { maintenanceFlg: true, }});
		} else if (error.name === 'CustomApiError') {
			//入力テキストに禁則文字が含まれている場合、azureのインフラルールによりエラーとなる
			//ただし、エラーは403 Forbiddenとしか返って来ない（＝禁則文字によるエラーだと判別できない）ため、メッセージを変更する
			const forbidCharPat1 = /.+403 Forbidden.+/;
			const forbidCharPat2 = /.+Microsoft-Azure-Application-Gateway.+/;
			if ((error.message.match(forbidCharPat1)) && (error.message.match(forbidCharPat2))) {
				const message = t('message.1000810');
				setDialogStatus(DIALOG_STATUS.OK_DIALOG);
				setDialogOpen(true);
				setDialogContent(message);
			} else {
				const message = error.message;
				setDialogStatus(DIALOG_STATUS.OK_DIALOG);
				setDialogOpen(true);
				setDialogContent(message);
			}
		} else {
			const message = 'アップロード処理でエラーが発生しました。\n(An error occurred during the upload process.)';
			setDialogStatus(DIALOG_STATUS.DETAIL_DIALOG);
			setDialogOpen(true);
			setDialogContent(message);
			setDetailContent(error.stack);
		}

		//認証期限切れの場合は再ログイン
		redirectToLogin();
	};

	/**
	 * ファイル選択時の処理
	 * @param {object} files 選択したファイルの情報
	 */
	const handleUploadFile = (files) => {
		const selectedFiles = unionWith(
			formValues.selectedFiles,
			files,
			(item1, item2) => {
				return item1.name === item2.name;
			},
		);
		const errFileNameList = selectedFiles.filter(selectedFile => checkFileName(selectedFile.name) === false).map(errFile => errFile.name);
		if (errFileNameList.length > 0) {
			setErrFileNameMessage(errFileNameList.join(', '));
		} else {
			setErrFileNameMessage('');
		}
		setFormValues({ ...formValues, selectedFiles });
	}

	/**
	 * ファイル削除時の処理
	 * @param {object} file 削除したファイルの情報
	 */
	const handleDeleteFile = (file) => {
		const selectedFiles = formValues.selectedFiles.filter((item) => item.name !== file.name);
		const errFileNameList = selectedFiles.filter(selectedFile => checkFileName(selectedFile.name) === false).map(errFile => errFile.name);
		if (errFileNameList.length > 0) {
			setErrFileNameMessage(errFileNameList.join(', '));
		} else {
			setErrFileNameMessage('');
		}
		setFormValues({ ...formValues, selectedFiles });
	}

	/**
	 * 公開日時で即時公開を選択時の処理
	 */
	const selectPublishImmediately = () => {
		setFormValues({ ...formValues, promptyFlg: PROMPTLY_FLG.PUBLISH_IMMEDIATELY, openDate: null, openDisplayDate: moment(null), openTime: ''});
		clearOpenDateErrFlg();
		setOpenTimeFormatErrFlg(false);
	};

	/**
	 * 公開日時の日付入力時の処理
	 * @param {string} date 入力した日付
	 */
	const inputDate = (date) => {
		const option = {year: 'numeric', month: '2-digit', day: '2-digit'};
		const today = new Date().toLocaleDateString('ja-JP', option);
		if (date === '') {
			setFormValues({ ...formValues, openDate: null, openDisplayDate: null});
			clearOpenDateErrFlg();
		} else if (!(moment(date, 'yyyy/MM/DD', true).isValid())) {
			setFormValues({ ...formValues, openDate: moment(date, 'yyyy/MM/DD'), openDisplayDate: null});
			setOpenDateFormatErrFlg(true);
			setOpenDatePastErrFlg(false);
		} else if (today <= moment(date, 'yyyy/MM/DD', true)._d.toLocaleDateString('ja-JP', option)) {
			setFormValues({ ...formValues, openDate: moment(date, 'yyyy/MM/DD'), openDisplayDate: moment(date, 'yyyy/MM/DD')});
			clearOpenDateErrFlg();
		} else {
			setFormValues({ ...formValues, openDate: moment(date, 'yyyy/MM/DD'), openDisplayDate: moment(date, 'yyyy/MM/DD')});
			setOpenDateFormatErrFlg(false);
			setOpenDatePastErrFlg(true);
		}
	};

	/**
	 * 公開日のエラーフラグをfalseに設定する処理
	 */
	const clearOpenDateErrFlg = () => {
		setOpenDateFormatErrFlg(false);
		setOpenDatePastErrFlg(false);
	};

	/**
	 * 公開日時の時刻入力時の処理
	 * @param {string} time 入力した時刻
	 */
	const inputTime = (time) => {
		setFormValues({ ...formValues, openTime: time});
		if (time === '' || moment(time, 'hh:mm A', true).isValid()) {
			setOpenTimeFormatErrFlg(false);
		} else {
			setOpenTimeFormatErrFlg(true);
		};
	};

	/**
	 * アップロードボタン押下時の処理
	 */
	const handleUploadClick = () => {
		var message = "";
		if (formValues.promptyFlg == "0") {
			var openDate = toISOStringWithTimezone(moment(formValues.openDate.format('yyyy-MM-DD') + ' ' + formValues.openTime)._d);
			var now = toISOStringWithTimezone(new Date());
			//指定した公開日時が現在時刻以前の場合はメッセージ表示
			if (openDate <= now) {
				message = t('message.1001300');
				setDialogStatus(DIALOG_STATUS.OK_DIALOG);
				setDialogOpen(true);
				setDialogContent(message);
			} else {
				message = t('message.1000500');
				setDialogStatus(DIALOG_STATUS.UPLOAD_CONFIRM_DIALOG);
				setConfirmDialogOpen(true);
				setConfirmDialogContent(message);
			}
		} else {
			message = t('message.1000500');
			setDialogStatus(DIALOG_STATUS.UPLOAD_CONFIRM_DIALOG);
			setConfirmDialogOpen(true);
			setConfirmDialogContent(message);
		}
		
	}

	/**
	 * ダイアログでOKボタン押下時の処理
	 */
	const onOk = () => {
		setDialogStatus(DIALOG_STATUS.NO_DIALOG);
		setDialogOpen(false);
	};

	/**
	 * アップロード時の確認ダイアログでOKボタン押下時の処理
	 */
	const onUploadConfirmOk = async () => {
		setConfirmDialogOpen(false);
		// 納品アップロードリクエストAPI
		const url = setURL(API_URL.UPLOAD_IQVIA_UPLOAD);
		let formData = new FormData();
		formData.append('orgLevel0Code', formValues.orgCode);
		formData.append('auditCode', formValues.serviceCode);
		formData.append('projCode', formValues.projCode);
		formData.append('notices', formValues.notices);
		formData.append('information', formValues.information);
		if (formValues.promptyFlg === PROMPTLY_FLG.PUBLISH_SELECT_DATE) {
			formData.append('openDate', toISOStringWithTimezone(moment(formValues.openDate.format('yyyy-MM-DD') + ' ' + formValues.openTime)._d));
		};
		formData.append('noSendFlg', formValues.noSendFlg);
		formData.append('promptlyFlg', formValues.promptyFlg);
		for (let selectedFile of formValues.selectedFiles) {
			formData.append('file', selectedFile);
		}
		const postApiParam = {
			url: url,
			sysRole: SYS_ROLE.UPLOAD_DELIVERY,
			body: formData,
			success: uploadSuccess,
			error: uploadError,
		};
		postApi(postApiParam);
	};

	/**
	 * 確認ダイアログでキャンセルボタン押下時の処理
	 */
	const onCancel = () => {
		setDialogStatus(DIALOG_STATUS.NO_DIALOG);
		setConfirmDialogOpen(false);
	};

	/**
	 * 共通納品ファイル管理遷移処理
	 */
	const commonBack = () => {
		const param = {
			org: formValues.orgCode,
			service: formValues.serviceCode,
			proj: formValues.projCode,
		};
		// 納品ファイル管理画面へ遷移
		navigate('/deliveryfilemanagement', { state: param });
	};

	/**
	 * アップロード完了ダイアログでOKボタン押下時の処理
	 */
	const onCompleteOk = () => {
		setDialogStatus(DIALOG_STATUS.NO_DIALOG);
		setDialogOpen(false);
		commonBack();
	};

	/**
	 * キャンセルボタン押下時の処理
	 */
	const handleCancelClick = () => {
		commonBack();
	}

	useEffect(() => {
		(async () => {
			if (location.state === null) {
				// 納品ファイル管理画面へ遷移
				navigate('/deliveryFilemanagement');
				return;
			}
			// ファイル選択の権限フラグを設定
			setUploadFileSelectorAuthFlg(getFuncroleFlg(SYS_ROLE.UPLOAD_DELIVERY, OBJ_ROLE.UPLOAD_FILE_SELECTOR));
			// 特記事項の権限フラグを設定
			setNoticeAuthFlg(getFuncroleFlg(SYS_ROLE.UPLOAD_DELIVERY, OBJ_ROLE.NOTICES));
			// お知らせの権限フラグを設定
			setInformationAuthFlg(getFuncroleFlg(SYS_ROLE.UPLOAD_DELIVERY, OBJ_ROLE.INFORMATION));
			// 公開日時の権限フラグを設定
			setOpenDateChkAuthFlg(getFuncroleFlg(SYS_ROLE.UPLOAD_DELIVERY, OBJ_ROLE.OPEN_DATE_CHK));
			// 通知メールの送信の権限フラグを設定
			setMailSendChkAuthFlg(getFuncroleFlg(SYS_ROLE.UPLOAD_DELIVERY, OBJ_ROLE.MAIL_SEND_CHK));
			// アップロードボタンの権限フラグを設定
			setUploadFileBtnAuthFlg(getFuncroleFlg(SYS_ROLE.UPLOAD_DELIVERY, OBJ_ROLE.UPLOAD_FILE_BTN));
			setFormValues({ ...formValues, ...location.state });
			setIsLoading(false);
		})();
	}, []);

	if (isLoading) {
		// ロード中の場合はローディング画面
		return (
			<Loading />
		);
	} else {
		return (
			<Container className="uploadDelivery" maxWidth="md">
				<Card className="card">
					<CardHeader
						title={t('uploadDelivery')}
						subheader={t('uploadDeliveryScreenDescription')}
					/>
					<CardContent>
						<Grid container>
							<Grid item md={12} className="griditem">
								<TextField
									label={'1. ' + t('selectOrg')}
									value={formValues.orgName}
									disabled
								/>
							</Grid>
							<Grid item md={12} className="griditem">
								<TextField
									label={'2. ' + t('selectService')}
									value={formValues.serviceName}
									disabled
								/>
							</Grid>
							<Grid item md={12} className="griditem">
								<TextField
									label={'3. ' + t('selectProj')}
									value={formValues.projName}
									disabled
								/>
							</Grid>
							<Grid item md={12} className="griditem uploadFileSelector">
								<FileUpload
									label={'4. ' + t('uploadFileSelect')}
									required
									value={formValues.selectedFiles}
									onUpload={handleUploadFile}
									onFileDelete={handleDeleteFile}
									dropAreaLabels={{
										browseLinkText: t('dropAreaBrowseLinkText'),
										hoverText: t('dropAreaHoverText'),
									}}
									disabled={!uploadFileSelectorAuthFlg}
									fullWidth
								/>
								{formValues.selectedFiles.length === 0 && (
									<Typography className='errorMessage' variant='caption'>
										{setMessage(t('message.1000200'), [t('file')])}
									</Typography>
								)}
								{errFileNameMessage.length !== 0 && (
									<Typography className='errorMessage' variant='caption'>
										{setMessage(t('message.1000800'), [errFileNameMessage])}
									</Typography>
								)}
							</Grid>
						</Grid>
						<Grid item md={12} className="griditem notices">
							<TextField
								label={'5. ' + t('notice')}
								helperText={formValues.notices.length > 300 ? setMessage(t('message.1000300'), [t('notice').toLowerCase(), 300]) : t('helperTextNotice')}
								value={formValues.notices}
								onChange={(e) => setFormValues({ ...formValues, notices: e.target.value })}
								error={formValues.notices.length > 300}
								multiline
								minRows="2"
								optional
								fullWidth
								disabled={!noticeAuthFlg}
							/>
						</Grid>
						<Grid item md={12} className="griditem information">
							<TextField
								label={'6. ' + t('information')}
								helperText={formValues.information.length > 300 ? setMessage(t('message.1000300'), [t('information').toLowerCase(), 300]) : t('helperTextInformation')}
								value={formValues.information}
								onChange={(e) => setFormValues({ ...formValues, information: e.target.value })}
								error={formValues.information.length > 300}
								multiline
								minRows="2"
								optional
								fullWidth
								disabled={!informationAuthFlg}
							/>
						</Grid>
						<Grid item md={12} className="griditem openDateChk">
							<InputLabel required>{'7. ' + t('publishedDate')}</InputLabel>
							<Radio
								label={t('publishImmediately')}
								checked={formValues.promptyFlg === PROMPTLY_FLG.PUBLISH_IMMEDIATELY}
								onChange={selectPublishImmediately}
								disabled={!openDateChkAuthFlg || formValues.publishSelectedDate}
							/>
							<br />
							<Radio
								label={t('publishSelectedDatetime')}
								checked={formValues.promptyFlg === PROMPTLY_FLG.PUBLISH_SELECT_DATE}
								onChange={() => setFormValues({ ...formValues, promptyFlg: PROMPTLY_FLG.PUBLISH_SELECT_DATE})}
								disabled={!openDateChkAuthFlg || formValues.publishImmediately}
							/>
							<div>
								<DatePicker
									className='openDate'
									value={formValues.openDisplayDate}
									helperText={openDateFormatErrFlg ? t('message.1000400') : openDatePastErrFlg ? t('message.1001300') : ''}
									onInputChange={inputDate}
									minDate={new Date()}
									error={openDateFormatErrFlg || openDatePastErrFlg}
									placeholder="yyyy/mm/dd"
									dateFormat='yyyy/MM/DD'
									disabled={!openDateChkAuthFlg || formValues.promptyFlg === PROMPTLY_FLG.PUBLISH_IMMEDIATELY}
								/>
								<TimePicker
									value={formValues.openTime}
									helperText={openTimeFormatErrFlg ? t('message.1001600') : ''}
									onChange={inputTime}
									error={openTimeFormatErrFlg}
									disabled={!openDateChkAuthFlg || formValues.promptyFlg === PROMPTLY_FLG.PUBLISH_IMMEDIATELY}
								/>
							</div>
							{formValues.promptyFlg === PROMPTLY_FLG.PUBLISH_SELECT_DATE && (!formValues.openDate || formValues.openTime === '') && (
								<Typography className='errorMessage' variant='caption'>
									{t('message.1001400')}
								</Typography>
							)}
						</Grid>
						<Grid item md={12} className="griditem mailSendChk">
							<InputLabel required>{'8. ' + t('sendMail')}</InputLabel>
							<div />
							<Radio
								label={t('sendPublishDate')}
								checked={formValues.noSendFlg === NO_SEND_FLG.SEND}
								onChange={() => setFormValues({ ...formValues, noSendFlg: NO_SEND_FLG.SEND })}
								disabled={!mailSendChkAuthFlg}
							/>
							<br />
							<Radio
								label={t('noSend')}
								checked={formValues.noSendFlg === NO_SEND_FLG.NO_SEND}
								onChange={() => setFormValues({ ...formValues, noSendFlg: NO_SEND_FLG.NO_SEND })}
								disabled={!mailSendChkAuthFlg}
							/>
						</Grid>
						<Divider className="divider" type="dark" />
						<Button
							className="uploadFileBtn"
							variant="primary"
							icon={Upload}
							disabled={formValues.selectedFiles.length === 0 || errFileNameMessage.length !== 0 || formValues.notices.length > 300 || formValues.information.length > 300 || openDateFormatErrFlg || openDatePastErrFlg || openTimeFormatErrFlg || (formValues.promptyFlg === PROMPTLY_FLG.PUBLISH_SELECT_DATE && (!formValues.openDate || formValues.openTime === ''))}
							onClick={handleUploadClick}
							style={{ display: uploadFileBtnAuthFlg ? "inline-flex" : "none"}}
						>
							{t('upload')}
						</Button>
						<Button
							className="cancelBtn"
							variant="secondary"
							icon={Close}
							onClick={handleCancelClick}
						>
							{t('cancel')}
						</Button>
					</CardContent>
				</Card>
				{dialogStatus === DIALOG_STATUS.OK_DIALOG && (
					<Dialog
						isOpen={dialogOpen}
						content={dialogContent}
						onOk={onOk}
					/>
				)}
				{dialogStatus === DIALOG_STATUS.DETAIL_DIALOG && (
					<DetailDialog
						isOpen={dialogOpen}
						content={dialogContent}
						detailContent={detailContent}
						onOk={onOk}
					/>
				)}
				{dialogStatus === DIALOG_STATUS.UPLOAD_CONFIRM_DIALOG && (
					<ConfirmDialog
						isOpen={confirmDialogOpen}
						content={confirmDialogContent}
						onOk={onUploadConfirmOk}
						onCancel={onCancel}
					/>
				)}
				{dialogStatus === DIALOG_STATUS.UPLOAD_COMPLETE_DIALOG && (
					<Dialog
						isOpen={dialogOpen}
						content={dialogContent}
						onOk={onCompleteOk}
					/>
				)}
		</Container>
	);
}
}

export default UploadDelivery;
