export const STATUS_CODE = {
    STORAGE_COMPLETE: '6',
    DELIVERY_UPLOAD_COMPLETE: '10',
    DELIVERY_STORAGE_COMPLETE: '12',
};

export const NO_SEND_FLG = {
    SEND: '0',
    NO_SEND: '1'
};

export const PROMPTLY_FLG = {
    PUBLISH_SELECT_DATE: '0',
    PUBLISH_IMMEDIATELY: '1'
};

export const API_URL = {
    LOGIN: 'login',
    LOGIN_INDEX: 'login/index',
    TOKEN: 'login/token',
    USERID: 'login/userid',
    FUNCROLE: 'funcrole',
    NOTICE: 'notice',
    SELECT_ITEM_ORGLEVEL0: 'selectitem/orglevel0',
    SELECT_ITEM_AUDIT: 'selectitem/audit',
    SELECT_ITEM_PROJ: 'selectitem/proj',
    UPHIST_CLIENT: 'uphist/client',
    UPHIST_IQVIA: 'uphist/iqvia',
    CSV_FILE: 'CSVFile',
    DOWNLOAD: 'download',
    PROJECT: 'project',
    UPLOAD_CLIENT: 'upload/client',
    UPLOAD_IQVIA_UPLOAD: 'upload/iqvia/upload',
    UPLOAD_IQVIA_REPLACE: 'upload/iqvia/replace',
    UPFILE: 'upfile',
};

export const SYS_ROLE = {
    APP_BAR: 'AppBar',
    PORTAL: 'Portal',
    FILE_MANAGEMENT: 'FileManagement',
    DELIVERY_FILE_MANAGEMENT: 'DeliveryFileManagement',
    UPLOAD_PAGE: 'UploadPage',
    UPLOAD_DELIVERY: 'UploadDelivery',
    UPLOAD_UPDATE: 'UploadUpdate',
};

export const OBJ_ROLE = {
    HOME_LINK: 'homeLink',
    FILE_MANAGEMENT_LINK: 'fileManagementLink',
    DELIVERY_FILE_MANAGEMENT_LINK: 'deliveryFileManagementLink',
    CHANGE_LANGUAGE: 'changeLanguage',
    ORG_SELECTOR: 'orgSelector',
    SERVICE_SELECTOR: 'serviceSelector',
    PROJ_SELECTOR: 'projSelector',
    DELIVERY_DATE_RANGE: 'deliveryDateRange',
    DOWNLOAD_LIST_BTN: 'downloadListBtn',
    UPLOAD_FILE_SELECTOR: 'uploadFileSelector',
    NOTICES: 'notices',
    UPLOAD_FILE_BTN: 'uploadFileBtn',
    INFORMATION: 'information',
    OPEN_DATE_CHK: 'openDateChk',
    MAIL_SEND_CHK: 'mailSendChk',
    DOWNLOAD_FILE_LINK: 'downloadFileLink',
};