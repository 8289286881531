import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Globe from 'apollo-react-icons/Globe';
import List from 'apollo-react-icons/List';
import PaperPlane from 'apollo-react-icons/PaperPlane';
import Question from 'apollo-react-icons/Question';
import ApolloWaves from 'apollo-react/components/NavigationBar/ApolloWaves';
import classnames from "classnames";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { OBJ_ROLE, SYS_ROLE } from './common/Constants';
import { getFuncroleFlg } from './common/Utils';

const ChangeLanguage = () => {
	const { t, i18n } = useTranslation();
	const [lang, setLang] = useState('ja');
	const [anchorElNav, setAnchorElNav] = useState(null);
	const menuItems = [
		{
			code: "ja",
			name: "日本語",
		},
		{
			code: "en",
			name: "English",
		}
	]

	const handleClick = (event) => {
		setAnchorElNav(event.currentTarget);
	  };

	  const handleClose = () => {
		setAnchorElNav(null);
	  };

	useEffect(() => {
		i18n.changeLanguage(lang);
	  }, [lang, i18n]);

	return (
		<span>
			<Button className="changeLanguage" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style={{ display: getFuncroleFlg(SYS_ROLE.APP_BAR, OBJ_ROLE.CHANGE_LANGUAGE) ? "inline-flex" : "none"}}>
				<><Globe />{menuItems.find(menuItem => menuItem.code === lang).name}</>
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorElNav}
				keepMounted
				open={Boolean(anchorElNav)}
				onClose={handleClose}
			>
				{menuItems.map((menuItem) => (
					<MenuItem key={menuItem.code} onClick={() => setLang(menuItem.code)}>
						<><Globe /><span className="changeLanguageLang">{menuItem.name}</span></>
					</MenuItem>
				))}
			</Menu>
		</span>
	);
}

function ResponsiveAppBar() {
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const [anchorElNav, setAnchorElNav] = useState(null);

	const isActive = (path) => {
		const currentPath = location.pathname;
		return currentPath === path;
	};

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	return (
		<AppBar position="sticky" className="app-nabvar">
			<Container maxWidth="xl" className="app-nabvar-container">
				<Toolbar disableGutters variant="dense">
					<Typography
						variant="h7"
						noWrap
						href="#"
						sx={{
							mr: 2,
							display: { xs: 'none', md: 'flex' },
							fontWeight: 700,
							letterSpacing: '.1rem',
							color: 'inherit',
							textDecoration: 'none',
						}}
					>
						<PaperPlane className="logo" />IQVIA Front
					</Typography>

					<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="inherit"
						>
							<List style={{ color: '#fff' }} />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: 'block', md: 'none' },
							}}
						>
							<MenuItem onClick={handleCloseNavMenu} style={{ display: getFuncroleFlg(SYS_ROLE.APP_BAR, OBJ_ROLE.HOME_LINK) ? "block" : "none"}}>
								<NavLink to="/" className="homeLink">
									{t('home')}
								</NavLink>
							</MenuItem>
							<MenuItem onClick={handleCloseNavMenu} style={{ display: getFuncroleFlg(SYS_ROLE.APP_BAR, OBJ_ROLE.FILE_MANAGEMENT_LINK) ? "block" : "none"}}>
								<NavLink to="/filemanagement" className="fileManagementLink">
									{t('fileManagement')}
								</NavLink>
							</MenuItem>
							<MenuItem onClick={handleCloseNavMenu} style={{ display: getFuncroleFlg(SYS_ROLE.APP_BAR, OBJ_ROLE.DELIVERY_FILE_MANAGEMENT_LINK) ? "block" : "none"}}>
								<NavLink to="/deliveryfilemanagement" className="deliveryFileManagementLink">
									{t('deliveryFileManagement')}
								</NavLink>
							</MenuItem>
						</Menu>
					</Box>
					<Typography
						variant="h7"
						noWrap
						href="#app-bar-with-responsive-menu"
						sx={{
							mr: 2,
							display: { xs: 'flex', md: 'none' },
							flexGrow: 1,
							fontWeight: 700,
							letterSpacing: '.1rem',
							color: 'inherit',
							textDecoration: 'none',
						}}
					>
						<PaperPlane className="logo" />IQVIA Front
					</Typography>
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
						<Button style={{ display: getFuncroleFlg(SYS_ROLE.APP_BAR, OBJ_ROLE.HOME_LINK) ? "block" : "none"}}>
							<NavLink to="/" className="homeLink">
								{t('home')}
							</NavLink>
						</Button>
						<Button style={{ display: getFuncroleFlg(SYS_ROLE.APP_BAR, OBJ_ROLE.FILE_MANAGEMENT_LINK) ? "block" : "none"}}>
							<NavLink to="/filemanagement" className={classnames("fileManagementLink", isActive('/upload') ? 'active' : '')}>
								{t('fileManagement')}
							</NavLink>
						</Button>
						<Button style={{ display: getFuncroleFlg(SYS_ROLE.APP_BAR, OBJ_ROLE.DELIVERY_FILE_MANAGEMENT_LINK) ? "block" : "none"}}>
							<NavLink to="/deliveryfilemanagement" className={classnames("deliveryFileManagementLink", isActive('/uploaddelivery') || isActive('/uploadupdate') ? 'active' : '')}>
								{t('deliveryFileManagement')}
							</NavLink>
						</Button>
					</Box>
					<Box sx={{ flexGrow: 0 }}>
						<ChangeLanguage />
						<IconButton style={{ color: 'inherit' }} component="a" href="/IQVIA Front操作マニュアル.pdf" target="_blank" rel="noreferrer noopener">
							<Question />
						</IconButton>
					</Box>
				</Toolbar>
			</Container>
			<ApolloWaves variant="brand" className="navbarbackground" />
		</AppBar>
	);
}
export default ResponsiveAppBar;