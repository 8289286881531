import Card from 'apollo-react/components/Card';
import CardContent from 'apollo-react/components/CardContent';
import CardHeader from 'apollo-react/components/CardHeader';
import Container from 'apollo-react/components/Container';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import parse from 'html-react-parser';
import { marked } from 'marked';
import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// TODO 認証処理が完了次第useNavigateを削除
import { useNavigate, useLocation } from 'react-router-dom';
import { API_URL, SYS_ROLE } from '../common/Constants';
import DetailDialog from '../common/DetailDialog';
import Dialog from '../common/Dialog';
import Loading from '../common/Loading';
import { getApi, setURL, redirectToLogin } from '../common/Utils';
import authData from '../json/authData.json';
import userIdData from '../json/userIdData.json';
import funcroleData from '../json/funcroleData.json';
import noticeData from '../json/noticeData.json';
import './Portal.scss';

/**
 * ホーム
 */
function Portal() {
	const location = useLocation();
	// TODO 認証処理が完了次第useNavigate処理を削除
	const navigate = useNavigate();
	const [t, i18n] = useTranslation();
	const [isLoading, setIsLoading] = useState(true);
	const DIALOG_STATUS = {
		NO_DIALOG: '0',
		OK_DIALOG: '1',
		DETAIL_DIALOG: '2',
	};
	const [dialogStatus, setDialogStatus] = useState(DIALOG_STATUS.NO_DIALOG);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogContent, setDialogContent] = useState('');
	const [detailContent, setDetailContent] = useState('');

	const [maintenanceFlg, setMaintenanceFlg] = useState(false);
	const [portalList, setPortalList] = useState([]);

	/**
	 * ダイアログでOKボタン押下時の処理
	 */
	const onOk = () => {
		setDialogOpen(false);
	};

	/**
	 * 認証APIの成功時の処理
	 */
	function authApiSuccess(response) {
		localStorage.setItem('token', JSON.stringify(response));
	};

	/**
	 * 機能権限情報取得APIの成功時の処理
	 */
	function funcroleApiSuccess(response) {
		localStorage.setItem('funcroleList', JSON.stringify(response));
	};

	/**
	 * userid取得APIの成功時の処理
	 */
	function userIdApiSuccess(response) {
		localStorage.setItem('userid', JSON.stringify(response));
	};

	function noticeApiSuccess(response) {
		setPortalList(response);
	};

	/**
	 * 認証APIの失敗時の処理
	 * @param {object} error エラーオブジェクト
	 */
	function authApiError(error) {
		const detailErrorMessage = '認証処理でエラーが発生しました。\n(An error occurred during the authentication process.)';
		commonError(error, detailErrorMessage);
		redirectToLogin();
	};

	/**
	 * userid取得APIの失敗時の処理
	 * @param {object} error エラーオブジェクト
	 */
	function userIdApiError(error) {
		const detailErrorMessage = 'ユーザー情報取得処理でエラーが発生しました。\n(An error occurred while getting user info process.)';
		commonError(error, detailErrorMessage);
		//認証期限切れの場合は再ログイン
		redirectToLogin();
	};

	/**
	 * 機能権限情報取得APIの失敗時の処理
	 * @param {object} error エラーオブジェクト
	 */
	function funcroleApiError(error) {
		const detailErrorMessage = '機能権限情報取得処理でエラーが発生しました。\n(An error occurred during the function authority information acquisition process.)';
		commonError(error, detailErrorMessage);
		//認証期限切れの場合は再ログイン
		redirectToLogin();
	};

	function noticeApiError(error) {
		const detailErrorMessage = 'お知らせ情報取得処理でエラーが発生しました。\n(An error occurred during the process of acquiring notification information.)';
		commonError(error, detailErrorMessage);
		//認証期限切れの場合は再ログイン
		redirectToLogin();
	};

	/**
	 * 共通エラー処理
	 * @param {object} error エラーオブジェクト
	 * @param {string} detailErrorMessage 詳細ダイアログに設定するエラーメッセージ
	 */
	const commonError = (error, detailErrorMessage) => {
		if (error.name === 'MaintenanceApiError') {
			setMaintenanceFlg(true);
		} else if (error.name === 'CustomApiError') {
			const message = error.message;
			setDialogStatus(DIALOG_STATUS.OK_DIALOG);
			setDialogOpen(true);
			setDialogContent(message);
		} else {
			setDialogStatus(DIALOG_STATUS.DETAIL_DIALOG);
			setDialogOpen(true);
			setDialogContent(detailErrorMessage);
			setDetailContent(error.stack);
		}
	};

	useEffect(() => {
		(async () => {
			if (location.state !== null) {
				setMaintenanceFlg(true);
				setIsLoading(false);
				return;
			}

			//表示URLから認可コード取得
			const queryString = new URLSearchParams(document.location.search);
			var code = queryString.get('code');
			if (process.env.NODE_ENV !== 'production') {
				//ローカル起動のため、認証なしで動作させるためセット
				code = 'dev';
			}

			//認証済みの状態でお知らせ画面を開く、認可コードをlocalStorageにセットしていない、もしくは直近で取得した認可コードと相違する場合は再度認証処理を行う
			if (code !== null) {
				if ((window.localStorage.getItem(['code']) === null) || (window.localStorage.getItem(['code']) !== code )) {
					localStorage.setItem('code', code);
					// アクセストークン取得API
					const urlAuth = setURL(API_URL.TOKEN);
					const getAuthApiParam = {
						url: `${urlAuth}?code=${code}`,
						sysRole: SYS_ROLE.PORTAL,
						success: authApiSuccess,
						error: authApiError,
						json: authData,
					}
					await getApi(getAuthApiParam);
	
					//userid取得API
					const urlUserId = setURL(API_URL.USERID);
					const getUserIDApiParam = {
						url: urlUserId,
						sysRole: SYS_ROLE.PORTAL,
						success: userIdApiSuccess,
						error: userIdApiError,
						json: userIdData,
					}
					await getApi(getUserIDApiParam);
	
					// 機能権限情報取得API
					const urlFuncrole = setURL(API_URL.FUNCROLE);
					const getFuncroleApiParam = {
						url: urlFuncrole,
						sysRole: SYS_ROLE.PORTAL,
						success: funcroleApiSuccess,
						error: funcroleApiError,
						json: funcroleData,
					}
					await getApi(getFuncroleApiParam);
				}
			}
			
			// お知らせ情報取得API
			const url = setURL(API_URL.NOTICE);
			const getNoticeApiParam = {
			url: url,
				sysRole: SYS_ROLE.PORTAL,
				success: noticeApiSuccess,
				error: noticeApiError,
				json: noticeData
			}
			await getApi(getNoticeApiParam);

			//バーのリンクが表示されないため、ポータル画面再表示
			navigate('/portal');
			setIsLoading(false);
		})();
	}, []);

	if (isLoading) {
		// ロード中の場合はローディング画面
		return (
			<Loading />
		);
	} else {
		return (
			<Container className="portal" maxWidth="md">
				<Typography variant="subtitle" gutterBottom>
					{t('information')}
				</Typography>
				<Grid container spacing={2}>
					{maintenanceFlg && (
						<Grid item xs={12} gap={2}>
							<Card className="card">
								<CardHeader title={t('message.1001500')} />
							</Card>
						</Grid>
					)}
					{!maintenanceFlg && portalList.map((portal, index) => (
						<Grid key={index} item xs={12} gap={2}>
							<Card className="card">
								<CardHeader title={parse(marked.parseInline(portal.title))} />
								<CardContent>
									<Typography variant="body2">
										{parse(marked.parseInline(portal.comment))}
									</Typography>
								</CardContent>
							</Card>
						</Grid>
					))}
					{!maintenanceFlg && portalList.length === 0 && (
						<Grid item xs={12} gap={2}>
							<Card className="card">
								<CardHeader title={t('message.1000000')} />
							</Card>
						</Grid>
					)}
				</Grid>
				{dialogStatus === DIALOG_STATUS.OK_DIALOG && (
					<Dialog
						isOpen={dialogOpen}
						content={dialogContent}
						onOk={onOk}
					/>
				)}
				{dialogStatus === DIALOG_STATUS.DETAIL_DIALOG && (
					<DetailDialog
						isOpen={dialogOpen}
						content={dialogContent}
						detailContent={detailContent}
						onOk={onOk}
					/>
				)}
			</Container>
		);
	}
}
export default Portal;
