import { Link } from 'apollo-react';
import Download from 'apollo-react-icons/Download';
import Plus from 'apollo-react-icons/Plus';
import Trash from 'apollo-react-icons/Trash';
import Button from 'apollo-react/components/Button';
import DateRangePicker from 'apollo-react/components/DateRangePicker';
import Grid from 'apollo-react/components/Grid';
import IconButton from 'apollo-react/components/IconButton';
import Select from 'apollo-react/components/Select';
import Table, { compareStrings } from 'apollo-react/components/Table';
import Tooltip from 'apollo-react/components/Tooltip';
import { format } from 'date-fns';
import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmDialog from '../common/ConfirmDialog';
import { API_URL, OBJ_ROLE, STATUS_CODE, SYS_ROLE } from '../common/Constants';
import DetailDialog from '../common/DetailDialog';
import Dialog from '../common/Dialog';
import Loading from '../common/Loading';
import { deleteApi, getApi, getFuncroleFlg, setMessage, setURL, redirectToLogin } from '../common/Utils';
import deliveryFileManagementData from '../json/deliveryFileManagementData.json';
import downloadCsvListData from '../json/downloadCsvListData.json';
import selectItemAuditData from '../json/selectItemAuditData.json';
import selectItemOrgData from '../json/selectItemOrgData.json';
import selectItemProjData from '../json/selectItemProjData.json';
import './DeliveryFileManagement.scss';

/**
 * 納品ファイル管理
 */
function DeliveryFileManagement() {
	const location = useLocation();
	const [t, i18n] = useTranslation();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const DIALOG_STATUS = {
		NO_DIALOG: '0',
		OK_DIALOG: '1',
		DETAIL_DIALOG: '2',
		DELETE_CONFIRM_DIALOG: '3',
	};
	const [dialogStatus, setDialogStatus] = useState(DIALOG_STATUS.NO_DIALOG);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogContent, setDialogContent] = useState('');
	const [detailContent, setDetailContent] = useState('');
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const [confirmDialogContent, setConfirmDialogContent] = useState('');

	// 企業名選択の権限フラグ
	const [orgSelectorAuthFlg, setOrgSelectorAuthFlg] = useState(false);
	// サービス名選択の権限フラグ
	const [serviceSelectorAuthFlg, setServiceSelectorAuthFlg] = useState(false);
	// プロジェクト名選択の権限フラグ
	const [projSelectorAuthFlg, setProjSelectorAuthFlg] = useState(false);
	// アップロード日選択の権限フラグ
	const [deliveryDateRangeAuthFlg, setDeliveryDateRangeAuthFlg] = useState(false);
	// 納品アップロードボタンの権限フラグ
	const [deliveryUploadBtnAuthFlg, setDeliveryUploadBtnAuthFlg] = useState(false);
	// 一覧のダウンロードボタンの権限フラグ
	const [downloadListBtnAuthFlg, setDownloadListBtnAuthFlg] = useState(false);
	// 一覧のダウンロードボタンの表示制御(活性/非活性)※一覧の表示件数により設定
	const [downloadListBtnDisabled, setDownloadListBtnDisabled] = useState(true);
	// 納品アップロード変更リンクの権限フラグ
	const [upFileLinkAuthFlg, setUpFileLinkAuthFlg] = useState(false);
	// 削除ボタンの権限フラグ
	const [deleteFileBtnAuthFlg, setDeleteFileBtnAuthFlg] = useState(false);

	// 企業名の選択リスト
	const [orgList, setOrgList] = useState([]);
	// サービス名の選択リスト
	const [serviceList, setServiceList] = useState([]);
	// プロジェクト名の選択リスト
	const [projList, setProjList] = useState([]);
	// 納品アップロードボタンの権限リスト
	const [deliveryUploadBtnAuthList, setDeliveryUploadBtnAuthList] = useState([]);
	// 納品アップロード変更リンクの権限リスト
	const [upFileLinkAuthList, setUpFileLinkAuthList] = useState([]);
	// 削除ボタンの権限リスト
	const [deleteFileBtnAuthList, setDeleteFileBtnAuthList] = useState([]);
	// 一覧の表示リスト
	const [deliveryFileManagementList, setDeliveryFileManagementList] = useState([]);
	// 検索条件で選択している情報
	const [formValues, setFormValues] = useState({
		org: '',
		service: '',
		proj: '',
		startDate: null,
		endDate: null,
		startDateErrFlg: false,
		endDateErrFlg: false,
	});
	// 選択しているデータ
	const [selectedData, setSelectedData] = useState({
		projRateNo: '',
		fileId: '',
	});

	const FileNameCell = ({ row }) => {
		const fileNameCellDisabledCondition = !upFileLinkAuthFlg || (row.projStatus !== STATUS_CODE.DELIVERY_UPLOAD_COMPLETE && row.projStatus !== STATUS_CODE.DELIVERY_STORAGE_COMPLETE);
		return <Link className="upFileLink" onClick={() => handleLinkClick(row)} disabled={fileNameCellDisabledCondition}>{row.fileName}</Link>;
	};

	const EntryDateCell = ({ row }) => {
		const ret = row.entryDate !== null ? format(new Date(row.entryDate), 'yyyy/MM/dd HH:mm') : '';
		return ret;
	};

	const OpenDateCell = ({ row }) => {
		const ret = row.openDate !== null ? format(new Date(row.openDate), 'yyyy/MM/dd HH:mm') : '';
		return ret;
	};

	const DeleteCell = ({ row }) => {
		const deleteCellDisplayCondition = deleteFileBtnAuthFlg && (row.projStatus === STATUS_CODE.DELIVERY_UPLOAD_COMPLETE || row.projStatus === STATUS_CODE.DELIVERY_STORAGE_COMPLETE);
		return (
			<div>
				<Tooltip title={t('delete')} disableFocusListener placement="right">
					<span>
						<IconButton
							className="deleteFileBtn"
							size="small"
							onClick={() => handleDeleteClick(row)}
							style={{ display: deleteCellDisplayCondition ? "block" : "none" }}
						>
							<Trash/>
						</IconButton>
					</span>
				</Tooltip>
			</div>
		);
	}

	const columns = [
		{
			header: t('fileName'),
			accessor: 'fileName',
			customCell: FileNameCell,
			sortFunction: compareStrings,
		},
		{
			header: t('uploadDate'),
			accessor: 'entryDate',
			customCell: EntryDateCell,
			fixedWidth: true,
			sortFunction: compareStrings,
			width: 160,
		},
		{
			header: t('uploadUser'),
			accessor: 'upUsr',
			sortFunction: compareStrings,
		},
		{
			header: t('notice'),
			accessor: 'notices',
			sortFunction: compareStrings,
		},
		{
			header: t('publicDate'),
			accessor: 'openDate',
			customCell: OpenDateCell,
			fixedWidth: true,
			sortFunction: compareStrings,
			width: 160,
		},
		{
			header: t('status'),
			accessor: 'statusName',
			sortFunction: compareStrings,
		},
		{
			header: '',
			accessor: 'delete',
			customCell: DeleteCell,
			width: 40,
		},
	];

	/**
	 * 企業選択項目取得APIの成功時の処理
	 * @param {array} response 企業選択項目取得APIのレスポンス
	 */
	function selectOrgApiSuccess(response) {
		if (location.state !== null) {
			// アップロード画面からの遷移の場合、サービス選択項目取得処理実行
			commonGetService(location.state.org);
		} else if (response.length === 1) {
			// 企業名の選択肢が1つのみの場合、サービス選択項目取得処理実行
			commonGetService(response[0].orgLevel0Code);
		}

		// 企業名選択リストをセット
		setOrgList(response);
	};

	/**
	 * サービス選択項目取得APIの成功時の処理
	 * @param {array} response サービス選択項目取得APIのレスポンス
	 * @param {string} orgCode 企業コード
	 */
	function selectServiceApiSuccess(response, orgCode) {
		if (location.state !== null) {
			// アップロード画面からの遷移の場合、プロジェクト選択項目取得処理実行
			commonGetProj(location.state.org, location.state.service);
		} else if (response.length === 1) {
			// サービス名の選択肢が1つのみの場合、プロジェクト選択項目取得処理実行
			commonGetProj(orgCode, response[0].auditCode);
		} else {
			setFormValues({ ...formValues, ...{org: orgCode, service: '', proj: ''} });
		}

		// サービス名選択リストをセット
		setServiceList(response);
	};

	/**
	 * プロジェクト選択項目取得APIの成功時の処理
	 * @param {object} response プロジェクト選択項目取得APIのレスポンス
	 * @param {object} option 選択している企業コード、サービスコードが設定されているオブジェクト
	 */
	function selectProjApiSuccess(response, option) {
		let updObj = {}
		if (location.state !== null) {
			updObj = location.state;
			location.state = null;
		} else if (response.proj.length === 1) {
			updObj = {org: option.orgCode, service: option.auditCode, proj: response.proj[0].projCode}
		} else {
			updObj = {org: option.orgCode, service: option.auditCode, proj: ''}
		}
		setFormValues({ ...formValues, ...updObj });

		// formValuesはレンダリング後に値が反映されるため、ディープコピーして別の変数に値をセットする
		let currentObj = structuredClone(formValues);
		currentObj = { ...currentObj, ...updObj };
			search(currentObj, {deliveryUploadBtnAuthList: response.uploadBtn, upFileLinkAuthList: response.upFileLink, deleteFileBtnAuthList: response.deleteFileBtn});

		// プロジェクト名選択リストをセット
		setProjList(response.proj);
		// 納品アップロードボタンの権限リストをセット
		setDeliveryUploadBtnAuthList(response.uploadBtn);
		// 納品アップロード変更リンクの権限リストをセット
		setUpFileLinkAuthList(response.upFileLink);
		// 削除ボタンの権限リストをセット
		setDeleteFileBtnAuthList(response.deleteFileBtn);
	};

	/**
	 * アップロード履歴(IQVIA用)取得APIの成功時の処理
	 * @param {object} response アップロード履歴(IQVIA用)取得APIのレスポンス
	 */
	function searchApiSuccess(response) {
		// 取得結果が0件の場合
		if (response.length === 0) {
			// 一覧のダウンロードボタンを非活性にする
			setDownloadListBtnDisabled(true);
		};
		if (response.length !== 0) {
			// 一覧のダウンロードボタンを活性にする
			setDownloadListBtnDisabled(false);
		};
		setDeliveryFileManagementList(response);
	};

	/**
	 * ダウンロードAPIの成功時の処理
	 * @param {object} response ダウンロードAPIのレスポンス
	 */
	async function downloadApiSuccess(response) {
		try {
			const fileName = response.fileDownloadName;
			const options = { 'suggestedName': fileName };
			const handle = await window.showSaveFilePicker(options);
			const writable = await handle.createWritable();
			const blob = new Blob([Uint8Array.from(atob(response.fileContents), str => str.charCodeAt(0))], {type: 'text/csv'});
			await writable.write(blob);
			await writable.close();
		} catch (error) {
		}
	};

	/**
	 * アップロード済ファイル削除APIの成功時の処理
	 */
	function deleteApiSuccess() {
		// 検索処理
		search(formValues);

		setDialogStatus(DIALOG_STATUS.OK_DIALOG);
		const message = t('message.1001100');
		setDialogOpen(true);
		setDialogContent(message);
	};

	/**
	 * 選択項目取得処理の失敗時の処理
	 * @param {object} error エラーオブジェクト
	 */
	function selectItemApiError(error) {
		const detailErrorMessage = '選択項目取得処理でエラーが発生しました。\n(An error occurred during the select item process.)';
		commonError(error, detailErrorMessage);

		//認証期限切れの場合は再ログイン
		redirectToLogin();
	};

	/**
	 * アップロード履歴(IQVIA用)取得APIの失敗時の処理
	 * @param {object} error エラーオブジェクト
	 */
	function searchApiError(error) {
		const detailErrorMessage = 'アップロード履歴取得処理でエラーが発生しました。\n(An error occurred in the upload history retrieval process.)';
		commonError(error, detailErrorMessage);

		//認証期限切れの場合は再ログイン
		redirectToLogin();
	};

	/**
	 * ダウンロードAPIの失敗時の処理
	 * @param {object} error エラーオブジェクト
	 */
	function downloadApiError(error) {
		const detailErrorMessage = 'ダウンロード処理でエラーが発生しました。\n(An error occurred during the download process.)';
		commonError(error, detailErrorMessage);

		//認証期限切れの場合は再ログイン
		redirectToLogin();
	};

	/**
	 * アップロード済ファイル削除APIの失敗時の処理
	 * @param {object} error エラーオブジェクト
	 */
	function deleteApiError(error) {
		const detailErrorMessage = 'アップロード済ファイル削除処理でエラーが発生しました。\n(An error occurred while deleting the uploaded files.)';
		commonError(error, detailErrorMessage);

		//認証期限切れの場合は再ログイン
		redirectToLogin();
	};

	/**
	 * 共通エラー処理
	 * @param {object} error エラーオブジェクト
	 * @param {string} detailErrorMessage 詳細ダイアログに設定するエラーメッセージ
	 */
	const commonError = (error, detailErrorMessage) => {
		if (error.name === 'MaintenanceApiError') {
			navigate('/', { state: { maintenanceFlg: true, }});
		} else if (error.name === 'CustomApiError') {
			const message = error.message;
			setDialogStatus(DIALOG_STATUS.OK_DIALOG);
			setDialogOpen(true);
			setDialogContent(message);
		} else {
			setDialogStatus(DIALOG_STATUS.DETAIL_DIALOG);
			setDialogOpen(true);
			setDialogContent(detailErrorMessage);
			setDetailContent(error.stack);
		}
	};

	/**
	 * クリア処理
	 */
	const clear = () => {
		setDownloadListBtnDisabled(true);
		setDeliveryUploadBtnAuthFlg(false);
		setUpFileLinkAuthFlg(false);
		setDeleteFileBtnAuthFlg(false);
		setDeliveryUploadBtnAuthList([]);
		setUpFileLinkAuthList([]);
		setDeleteFileBtnAuthList([]);
		setDeliveryFileManagementList([]);
	}

	/**
	 * 企業名の選択ダイアログで選択時の処理
	 * @param {object} event イベント
	 */
	const selectOrg = (event) => {
		clear();
		setServiceList([]);
		setProjList([]);
		if (event.target.value === '') {
			const updObj = {org: '', service: '', proj: ''};
			setFormValues({ ...formValues, ...updObj });
		} else {
			commonGetService(event.target.value);
		}
	};

	/**
	 * 共通サービス選択項目取得処理
	 * @param {string} orgCode 企業コード
	 */
	const commonGetService = async (orgCode) => {
		// サービス選択項目取得API
		const url = setURL(API_URL.SELECT_ITEM_AUDIT) + '?';
		// クエリパラメータ作成
		const params = {};
		params['orgLevel0Code'] = orgCode;
		const urlSearchParam =  String(new URLSearchParams(params));
		const getApiParam = {
			url: url + urlSearchParam,
			sysRole: SYS_ROLE.DELIVERY_FILE_MANAGEMENT,
			success: selectServiceApiSuccess,
			error: selectItemApiError,
			json: selectItemAuditData,
			option: orgCode
		}
		await getApi(getApiParam);
	};

	/**
	 * サービス名の選択ダイアログで選択時の処理
	 * @param {object} event イベント
	 */
	const selectService = (event) => {
		clear();
		setProjList([]);
		if (event.target.value === '') {
			const updObj = {service: '', proj: ''};
			setFormValues({ ...formValues, ...updObj });
		} else {
			commonGetProj(formValues.org, event.target.value);
		}
	};

	/**
	 * 共通プロジェクト選択項目取得処理
	 * @param {string} orgCode 企業コード
	 * @param {string} auditCode サービスコード
	 */
	const commonGetProj = async (orgCode, auditCode) => {
		// プロジェクト選択項目取得API
		const url = setURL(API_URL.SELECT_ITEM_PROJ) + '?';
		// クエリパラメータ作成
		const params = {};
		params['orgLevel0Code'] = orgCode;
		params['auditCode'] = auditCode;
		const urlSearchParam =  String(new URLSearchParams(params));
		const getApiParam = {
			url: url + urlSearchParam,
			sysRole: SYS_ROLE.DELIVERY_FILE_MANAGEMENT,
			success: selectProjApiSuccess,
			error: selectItemApiError,
			json: selectItemProjData,
			option: {orgCode: orgCode, auditCode: auditCode}
		}
		await getApi(getApiParam);
	};

	/**
	 * プロジェクト名の選択ダイアログで選択時の処理
	 * @param {object} event イベント
	 */
	const selectProj = (event) => {
		setDeliveryFileManagementList([]);
		// formValuesはレンダリング後に値が反映されるため、ディープコピーして別の変数に値をセットする
		let currentObj = structuredClone(formValues);

		const updObj = {proj: event.target.value}
		setFormValues({ ...formValues, ...updObj });
		currentObj = { ...currentObj, ...updObj };
		search(currentObj);
	};

	/**
	 * アップロード日の選択時の処理
	 * @param {object} event イベント
	 */
	const selectDate = (event) => {
		// formValuesはレンダリング後に値が反映されるため、ディープコピーして別の変数に値をセットする
		let currentObj = structuredClone(formValues);

		let updObj = {};
		if (event[0] !== null) {
			updObj.startDate = event[0]._d;
		} else {
			updObj.startDate = null;
		}
		if (event[1] !== null) {
			updObj.endDate = event[1]._d;
		} else {
			updObj.endDate = null;
		}
		updObj.startDateErrFlg = updObj.startDate === null ? false : isNaN(updObj.startDate.getDate());
		updObj.endDateErrFlg = updObj.endDate === null ? false : isNaN(updObj.endDate.getDate());
		setFormValues({ ...formValues, ...updObj });
		currentObj = { ...currentObj, ...updObj };
		search(currentObj);
	};

	/**
	 * 検索処理
	 * @param {object} currentObj 検索条件で選択している情報
	 * @param {object} authObj 権限のオブジェクト
	 */
	const search = async (currentObj, authObj = {deliveryUploadBtnAuthList: deliveryUploadBtnAuthList, upFileLinkAuthList: upFileLinkAuthList, deleteFileBtnAuthList: deleteFileBtnAuthList}) => {
		if (currentObj.service && currentObj.org && currentObj.proj) {
			// 納品アップロードボタンの権限フラグを設定
			setDeliveryUploadBtnAuthFlg(authObj.deliveryUploadBtnAuthList.find(deliveryUploadBtnAuth => deliveryUploadBtnAuth.orgLevel0Code === currentObj.org && deliveryUploadBtnAuth.auditCode === currentObj.service && deliveryUploadBtnAuth.projCode === currentObj.proj) !== undefined);
		}
		if (currentObj.service && currentObj.org && currentObj.proj && !currentObj.startDateErrFlg && !currentObj.endDateErrFlg) {
			// 納品アップロード変更リンクの権限フラグを設定
			setUpFileLinkAuthFlg(authObj.upFileLinkAuthList.find(upFileLinkAuth => upFileLinkAuth.orgLevel0Code === currentObj.org && upFileLinkAuth.auditCode === currentObj.service && upFileLinkAuth.projCode === currentObj.proj) !== undefined);
			// 削除ボタンの権限フラグを設定
			setDeleteFileBtnAuthFlg(authObj.deleteFileBtnAuthList.find(deleteFileBtnAuth => deleteFileBtnAuth.orgLevel0Code === currentObj.org && deleteFileBtnAuth.auditCode === currentObj.service && deleteFileBtnAuth.projCode === currentObj.proj) !== undefined);
			// アップロード履歴(IQVIA用)取得API
			const url = setURL(API_URL.UPHIST_IQVIA) + '?';

			// クエリパラメータ作成
			const params = {};
			params['orgLevel0Code'] = currentObj.org;
			params['auditCode'] = currentObj.service;
			params['projCode'] = currentObj.proj;
			const option = {year: 'numeric', month: '2-digit', day: '2-digit'};
			if (currentObj.startDate) {
				params['fromDate'] = currentObj.startDate.toLocaleDateString('ja-JP', option).split("/").join("-");
			};
			if (currentObj.endDate) {
				params['toDate'] = currentObj.endDate.toLocaleDateString('ja-JP', option).split("/").join("-");
			};
			const urlSearchParam =  String(new URLSearchParams(params));

			const getApiParam = {
				url: url + urlSearchParam,
				sysRole: SYS_ROLE.DELIVERY_FILE_MANAGEMENT,
				success: searchApiSuccess,
				error: searchApiError,
				json: deliveryFileManagementData
			}
			await getApi(getApiParam);
		} else {
			setDownloadListBtnDisabled(true);
			setDeliveryFileManagementList([]);
		}
	};

	/**
	 * 納品アップロードボタン押下時の処理
	 */
	const handleUploadClick = () => {
		// 企業名の取得
		const orgName = orgList.find(orgLevel0 => orgLevel0.orgLevel0Code === formValues.org)['orgAbbr'];
		// サービス名の取得
		const serviceName = serviceList.find(audit => audit.auditCode === formValues.service)['auditName'];
		// プロジェクト名の取得
		const projName = projList.find(proj => proj.projCode === formValues.proj)['projName'];
		const param = {
			orgCode: formValues.org,
			orgName: orgName,
			serviceCode: formValues.service,
			serviceName: serviceName,
			projCode: formValues.proj,
			projName: projName,
		};
		// 納品アップロード画面へ遷移
		navigate('/uploaddelivery', { state: param });
	};

	/**
	 * 一覧のダウンロードボタン押下時の処理
	 */
	const handleDownloadClick = async () => {
		// 納品アップロード一覧ダウンロードAPI
		const url = setURL(API_URL.CSV_FILE) + '?';

		// クエリパラメータ作成
		const params = {};
		if (formValues.org) {
			params['orgLevel0Code'] = formValues.org;
		};
		if (formValues.service) {
			params['auditCode'] = formValues.service;
		};
		if (formValues.proj) {
			params['projCode'] = formValues.proj;
		};
		const option = {year: 'numeric', month: '2-digit', day: '2-digit'};
		if (formValues.startDate) {
			params['fromDate'] = formValues.startDate.toLocaleDateString('ja-JP', option).split("/").join("-");
		};
		if (formValues.endDate) {
			params['toDate'] = formValues.endDate.toLocaleDateString('ja-JP', option).split("/").join("-");
		};
		const urlSearchParam =  String(new URLSearchParams(params));

		const getApiParam = {
			url: url + urlSearchParam,
			sysRole: SYS_ROLE.DELIVERY_FILE_MANAGEMENT,
			success: downloadApiSuccess,
			error: downloadApiError,
			json: downloadCsvListData
		}
		await getApi(getApiParam);
	};

	/**
	 * ファイル名のリンク押下時の処理
	 * @param {object} row 選択している行の情報
	 */
	const handleLinkClick = (row) => {
		// 企業名の取得
		const orgName = orgList.find(orgLevel0 => orgLevel0.orgLevel0Code === formValues.org)['orgAbbr'];
		// サービス名の取得
		const serviceName = serviceList.find(audit => audit.auditCode === formValues.service)['auditName'];
		// プロジェクト名の取得
		const projName = projList.find(proj => proj.projCode === formValues.proj)['projName'];
		const param = {
			orgCode: formValues.org,
			orgName: orgName,
			serviceCode: formValues.service,
			serviceName: serviceName,
			projCode: formValues.proj,
			projName: projName,
			projRateNo: row.projRateNo,
			projStatus: row.projStatus,
		};
		// 納品アップロード_変更画面へ遷移
		navigate('/uploadupdate', { state: param })
	};

	/**
	 * 削除ボタン押下時の処理
	 * @param {object} row 選択している行の情報
	 */
	const handleDeleteClick = (row) => {
		const selectedObj = { projRateNo: row.projRateNo, fileId: row.fileId };
		setSelectedData({ ...selectedData, ...selectedObj });
		const message = setMessage(t('message.1001000'), [row.fileName]);
		setDialogStatus(DIALOG_STATUS.DELETE_CONFIRM_DIALOG);
		setConfirmDialogOpen(true);
		setConfirmDialogContent(message);
	};

	/**
	 * ダイアログでOKボタン押下時の処理
	 */
	const onOk = () => {
		setDialogStatus(DIALOG_STATUS.NO_DIALOG);
		setDialogOpen(false);
	};

	/**
	 * 削除時の確認ダイアログでOKボタン押下時の処理
	 */
	const onDeleteConfirmOk = async () => {
		setConfirmDialogOpen(false);
		// アップロード済ファイル削除API
		const url = setURL(API_URL.UPFILE);
		let formData = new FormData();
		formData.append('auditCode', formValues.service);
		formData.append('projCode', formValues.proj);
		formData.append('projRateNo', selectedData.projRateNo);
		formData.append('fileid', selectedData.fileId);
		const deleteApiParam = {
			url: url,
			sysRole: SYS_ROLE.DELIVERY_FILE_MANAGEMENT,
			body: formData,
			success: deleteApiSuccess,
			error: deleteApiError,
		};
		await deleteApi(deleteApiParam);
	};

	/**
	 * 確認ダイアログでキャンセルボタン押下時の処理
	 */
	const onCancel = () => {
		setDialogStatus(DIALOG_STATUS.NO_DIALOG);
		setConfirmDialogOpen(false);
	};

	useEffect(() => {
		(async () => {
			if (window.localStorage.getItem(['token']) === null) {
				navigate('/')
				return;
			}
			// 企業名選択の権限フラグを設定
			const orgSelectorAuthFlg = getFuncroleFlg(SYS_ROLE.DELIVERY_FILE_MANAGEMENT, OBJ_ROLE.ORG_SELECTOR);
			setOrgSelectorAuthFlg(orgSelectorAuthFlg);
			// サービス名選択の権限フラグを設定
			const serviceSelectorAuthFlg = getFuncroleFlg(SYS_ROLE.DELIVERY_FILE_MANAGEMENT, OBJ_ROLE.SERVICE_SELECTOR);
			setServiceSelectorAuthFlg(serviceSelectorAuthFlg);
			// プロジェクト名選択の権限フラグを設定
			const projSelectorAuthFlg = getFuncroleFlg(SYS_ROLE.DELIVERY_FILE_MANAGEMENT, OBJ_ROLE.PROJ_SELECTOR);
			setProjSelectorAuthFlg(projSelectorAuthFlg);
			// アップロード日選択の権限フラグを設定
			setDeliveryDateRangeAuthFlg(getFuncroleFlg(SYS_ROLE.DELIVERY_FILE_MANAGEMENT, OBJ_ROLE.DELIVERY_DATE_RANGE));
			// 一覧のダウンロードボタンの権限フラグを設定
			setDownloadListBtnAuthFlg(getFuncroleFlg(SYS_ROLE.DELIVERY_FILE_MANAGEMENT, OBJ_ROLE.DOWNLOAD_LIST_BTN));

			if (orgSelectorAuthFlg && serviceSelectorAuthFlg && projSelectorAuthFlg) {
				// 企業選択項目取得API
				const url = setURL(API_URL.SELECT_ITEM_ORGLEVEL0);
				const getApiParam = {
					url: url,
					sysRole: SYS_ROLE.DELIVERY_FILE_MANAGEMENT,
					success: selectOrgApiSuccess,
					error: selectItemApiError,
					json: selectItemOrgData
				}
				await getApi(getApiParam);
			}
			setIsLoading(false);
		})();
	}, []);

	if (isLoading) {
		// ロード中の場合はローディング画面
		return (
			<Loading />
		);
	} else {
		return (
			<div className="deliveryFileManagement">
				<Grid container spacing={2} className="filter">
					<Grid item md={2} className="griditem">
						<Select
							name="org"
							label={t('org')}
							helperText={!formValues.org ? setMessage(t('message.1000200'), [t('org').toLowerCase()]) : ""}
							value={formValues.org}
							onChange={selectOrg}
							required
							error={!formValues.org}
							disabled={!orgSelectorAuthFlg}
							fullWidth
							className="orgSelector"
						>
							{orgList.map((org, index) => {
								return <option key={index} value={org.orgLevel0Code}>{org.orgAbbr}</option>;
							})}
						</Select>
					</Grid>
					<Grid item md={2} className="griditem">
						<Select
							name="service"
							label={t('service')}
							helperText={!formValues.service ? setMessage(t('message.1000200'), [t('service').toLowerCase()]) : ""}
							value={formValues.service}
							onChange={selectService}
							required
							error={!formValues.service}
							disabled={!serviceSelectorAuthFlg || !formValues.org}
							fullWidth
							className="serviceSelector"
						>
							{serviceList.map((service, index) => {
								return <option key={index} value={service.auditCode}>{service.auditName}</option>;
							})}
						</Select>
					</Grid>
					<Grid item md={3} className="griditem">
						<Select
							name="proj"
							label={t('proj')}
							helperText={!formValues.proj ? setMessage(t('message.1000200'), [t('proj').toLowerCase()]) : ""}
							value={formValues.proj}
							onChange={selectProj}
							required
							error={!formValues.proj}
							disabled={!projSelectorAuthFlg || !formValues.service}
							fullWidth
							className="projSelector"
						>
							{projList.map((proj, index) => {
								return <option key={index} value={proj.projCode}>{proj.projName}</option>;
							})}
						</Select>
					</Grid>
					<Grid item md={3} className="griditem">
						<DateRangePicker
							name="date"
							className="deliveryDateRange"
							startLabel={t('uploadDate')}
							endLabel="　"
							startProps={{ placeholder: 'From', error: formValues.startDateErrFlg }}
							endProps={{ placeholder: 'To', error: formValues.endDateErrFlg, helperText: formValues.endDateErrFlg ? t('message.1000400') : "" }}
							dateFormat='yyyy/MM/DD'
							helperText={formValues.startDateErrFlg ? t('message.1000400') : ""}
							onChange={selectDate}
							disabled={!deliveryDateRangeAuthFlg}
						/>
					</Grid>
				</Grid>
				<div className="buttons">
					<Button
						variant="primary"
						size="small"
						className="deliveryUploadBtn"
						status="success"
						onClick={handleUploadClick}
						disabled={!formValues.org || !formValues.service || !formValues.proj || !deliveryUploadBtnAuthFlg}
					>
						<Plus />{t('uploadDelivery')}
					</Button>
					<Button variant="primary" size="small" className="downloadListBtn" onClick={handleDownloadClick} disabled={downloadListBtnDisabled} style={{ display: downloadListBtnAuthFlg ? "inline-flex" : "none"}}>
						<Download />{t('downloadList')}
					</Button>
				</div>
				<Table
					className="downloadList"
					columns={columns}
					rowId="id"
					rows={deliveryFileManagementList}
					rowsPerPageOptions={[10, 50, 100, 'All']}
					rowProps={{ hover: false }}
					tablePaginationProps={{
						labelDisplayedRows: ({ from, to, count }) => `全${count}件`,
						truncate: true,
					}}
					emptyProps={{ text: t('message.1000100') }}
				/>
				{dialogStatus === DIALOG_STATUS.OK_DIALOG && (
					<Dialog
						isOpen={dialogOpen}
						content={dialogContent}
						onOk={onOk}
					/>
				)}
				{dialogStatus === DIALOG_STATUS.DETAIL_DIALOG && (
					<DetailDialog
						isOpen={dialogOpen}
						content={dialogContent}
						detailContent={detailContent}
						onOk={onOk}
					/>
				)}
				{dialogStatus === DIALOG_STATUS.DELETE_CONFIRM_DIALOG && (
					<ConfirmDialog
						isOpen={confirmDialogOpen}
						content={confirmDialogContent}
						onOk={onDeleteConfirmOk}
						onCancel={onCancel}
					/>
				)}
			</div >
		);
	}
}

export default DeliveryFileManagement;
