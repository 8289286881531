import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.scss';
import AppBar from './AppBar';
import Login from './Login';
import DeliveryFileManagement from './deliveryfilemanagement/DeliveryFileManagement';
import FileManagement from './filemanagement/FileManagement';
import Portal from './portal/Portal';
import UploadDelivery from './uploaddelivery/UploadDelivery';
import UploadPage from './uploadpage/UploadPage';
import UploadUpdate from './uploadupdate/UploadUpdate';

// TODO 認証処理実装後にログイン部分は削除
function App() {
	return (
		<BrowserRouter basename="/">
			<AppBar />
			<div className="app-content">
				<Routes>
					<Route path="/" element={<Portal />} />
					<Route path="/portal" element={<Portal />} />
					<Route path="/filemanagement" element={<FileManagement />} />
					<Route path="/upload" element={<UploadPage />} />
					<Route path="/deliveryfileManagement" element={<DeliveryFileManagement />} />
					<Route path="/uploaddelivery" element={<UploadDelivery />} />
					<Route path="/uploadupdate" element={<UploadUpdate />} />
				</Routes>
			</div>
		</BrowserRouter>
		// <BrowserRouter >
		// 	<AppBar />
		// 	<div className="app-content">
		// 		<Routes>
		// 			<Route path="/portal" element={<Portal />} />
		// 			<Route path="/filemanagement" element={<FileManagement />} />
		// 			<Route path="/upload" element={<UploadPage />} />
		// 			<Route path="/deliveryfileManagement" element={<DeliveryFileManagement />} />
		// 			<Route path="/uploaddelivery" element={<UploadDelivery />} />
		// 			<Route path="/uploadupdate" element={<UploadUpdate />} />
		// 		</Routes>
		// 	</div>
		// </BrowserRouter>
	);
}

export default App;
